.body-app {
    .is-sticky ~ #maincontent {
        padding-top: 51px;
    }

    .navbar-toggler {
        stroke: #111;
        padding-left: rem(20px) !important;
    }

    header .nav-mb {
        background: #fff;
    }

    .header-app-search-icon {
        fill: #111;
    }

    header .main-menu {
        overflow: auto !important;
    }

    .header-height-App {
        background-color: #fff !important;
    }

    .header-b-search-link {
        margin-right: 0;
    }

    .brand-app {
        position: relative !important;
        left: rem(9px) !important;
        padding-top: rem(12px);

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    .app-search-btn-padding {
        padding-right: 20px;
    }

    .header-app {
        height: 3.75rem !important;
        background-color: #fff !important;
    }

    .app-top-links {
        .sub-cat-item {
            color: #111 !important;
        }
    }

    .app-main-nav {
        z-index: 1030 !important;
    }

    .page-menu {
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
    }
}
