.btn {
    // dark button with light text
    text-transform: uppercase;
    box-shadow: none !important;

    &.btn-primary {
        color: $white;
        fill: $white;
        background-color: $black;
        border-color: $black;

        .inverted-layer {
            fill: $black;
        }

        &:hover {
            color: $white;
            fill: $white;
            background-color: $black;
            border-color: $black;

            .inverted-layer {
                fill: $black;
            }
        }
    }

    // light button with dark text
    &.btn-secondary {
        color: $black;
        background-color: $white;
        border-color: $black;

        .inverted-layer {
            fill: $white;
        }

        &:hover {
            color: $white;
            background-color: $black;
            border-color: $black;

            .inverted-layer {
                fill: $black;
            }
        }
    }

    // transparent button with light text
    &.btn-outline-primary {
        color: $white;
        background-color: transparent;
        border-color: $white;

        .inverted-layer {
            fill: $black;
        }

        &:hover {
            color: $black;
            background-color: $white;
            border-color: $black;

            .inverted-layer {
                fill: $white;
            }
        }
    }

    &.btn-outline-secondary {
        color: $black;
        background-color: transparent;
        border-color: $black;

        .inverted-layer {
            fill: $white;
        }

        &:hover {
            color: $white;
            background-color: $black;
            border-color: $white;

            .inverted-layer {
                fill: $black;
            }
        }
    }

    &.btn-subnav {
        text-transform: capitalize !important;
    }
}

button {
    &:hover {
        outline: none;
    }
}

.fa {
    &-check-square,
    &-square-o {
        vertical-align: middle;

        &::before {
            content: "";
            width: rem(16px);
            height: rem(16px);
            display: inline-block;
        }
    }

    &-check-square {
        &::before {
            background-image: $svg-checkbox-checked;
        }
    }

    &-square-o {
        &::before {
            background-image: $svg-checkbox-unchecked;
        }
    }
}
