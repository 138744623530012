.ratio {
    position: relative;

    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.ratio-1x1 {
        @include aspect-ratio(1);
    }

    &.ratio-1x1-ext {
        @include aspect-ratio(9, 16);

        @include media-breakpoint-up(md) {
            @include aspect-ratio(1);
        }
    }

    &.ratio-3x4 {
        @include aspect-ratio(3, 4);
    }

    &.ratio-2x3 {
        @include aspect-ratio(2, 3);
    }

    &.ratio-4x3 {
        @include aspect-ratio(4, 3);
    }

    &.ratio-11x15 {
        @include aspect-ratio(11, 15);
    }

    &.ratio-9x16 {
        @include aspect-ratio(9, 16);
    }

    &.ratio-16x9 {
        @include aspect-ratio(16, 9);
    }

    &.ratio-16x9-ext {
        @include aspect-ratio(9, 16);

        @include media-breakpoint-up(lg) {
            @include aspect-ratio(16, 9);
        }
    }
}

// embed vimeo video
.embed-video-container {
    position: relative;
    padding: 45.31% 0 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.position-center-absolute {
    @include centerElement(center, absolute);
}

// Text-color classes
//---------------------------------------

.text-primary,
.text-black {
    color: $primary;
}

.text-white {
    color: $white;
}

.bc-beige {
    background-color: $ps-beige;
}

// font-weight classes
//---------------------------------------

.font-regular {
    font-weight: $font-weight-normal;
}

.font-med {
    font-weight: $font-weight-medium;
}

.font-semi {
    font-weight: $font-weight-semibold;
}

.font-bold {
    font-weight: $font-weight-bold;
}

.font-bolder {
    font-weight: $font-weight-bolder;
}

// Full-Bleed (using VW's)
//---------------------------------------

@mixin full-width() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.full-width {
    @include full-width();
}

// can be applied to elements that already receive the full-bleed styling to override within a particular viewport

@mixin override-full-width() {
    width: auto;
    max-width: 0;
    margin-left: initial;
}

.override-full-width {
    @include full-width();
}

.medium-gray-color {
    color: $medium-gray !important;
}
