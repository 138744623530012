//----------------------------
// Base Elements
//----------------------------

body {
    min-width: rem(320px);
    font-family: $base-font-family;
    color: $body-color;
}

html {
    font-size: $font-size-base;
    font-family: $base-font-family;
}

img {
    max-width: 100%;
}

p,
.p {
    line-height: $line-height-body;
    margin-bottom: $spacer;
    font-size: $font-size-base;

    &.small,
    .small,
    small {
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }

    &.large,
    .large {
        font-size: $font-size-lg;
        line-height: $line-height-lg;
    }
}

label {
    .custom-checkbox &,
    .custom-radio & {
        text-transform: none;
        font-weight: $font-weight-normal;
    }
}

ul,
ol {
    padding-left: rem(18px);
    margin-bottom: 0;

    > li {
        line-height: 1.38;
        margin-bottom: rem(22px);
    }

    ul,
    ol {
        li {
            margin-bottom: rem(10px);

            &:last-of-type {
                margin-bottom: rem(22px);
            }
        }
    }
}

ul.unstyled,
ul.unstyled li,
ul.unstyled ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
}

ol {
    li {
        padding-left: rem(15px);
    }

    ol {
        list-style: upper-alpha;
        padding-left: rem(35px);
    }
}

.values.content.unstyled {
    min-width: rem(200px);
}

.fog-clear-filter {
    font-size: rem(12px);
}
