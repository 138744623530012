.shop-now-carousel {
    .item-tile {
        padding: 0 rem(8px);
        position: relative;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        h4 {
            color: $white;
            font-size: rem(32px);
            font-weight: 500;
            line-height: rem(36px);
            letter-spacing: -0.25px;
            left: rem(23px);
            position: absolute;
            top: rem(15px);

            @include media-breakpoint-up(md) {
                bottom: rem(92px);
                font-size: rem(38px);
                letter-spacing: -0.5px;
                line-height: rem(42px);
                left: rem(30px);
                top: auto;
            }
        }

        &.slick-slide {
            img {
                width: 100%;
            }
        }

        .btn {
            bottom: rem(15px);
            left: rem(23px);
            position: absolute;

            @include media-breakpoint-up(md) {
                bottom: rem(30px);
                left: rem(30px);
            }
        }
    }
}

.recommendations-default {
    .pl-3 {
        padding-left: 0 !important;
    }

    h2 {
        font-size: rem(24px);
        line-height: rem(28px);
        margin: 0 0 rem(21px) 0;

        @include media-breakpoint-up(md) {
            font-size: rem(32px);
            line-height: rem(36px);
            margin: 0 0 rem(30px) rem(-2px);
        }
    }
}
