@import "variables";
// Cart page
.cart {
    .line-item-name {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        white-space: normal;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .line-item-divider {
        opacity: 0.5;
        background-color: $light-gray;
        height: 1px;
        border: none;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.out-of-stock {
    width: 250%;
}

// Product Summary
.product-summary-block {
    .item-image {
        height: rem(128px);
        margin-right: rem(12px);
        width: rem(83px);

        img.product-image {
            max-height: rem(128px);
        }

        @include media-breakpoint-down(md) {
            height: 100% !important;
            max-height: 100% !important;
        }
    }

    .line-item-name {
        color: $black;
        font-weight: normal;
        font-size: rem(14px);
        line-height: rem(18px);
        margin-bottom: rem(8px);

        @include media-breakpoint-up(md) {
            font-size: rem(12px);
            line-height: rem(14px);
        }

        .pre-order-msg {
            font-size: rem(10px);
            line-height: rem(12px);
            font-weight: 600;
        }
    }

    .line-item-attributes {
        display: inline-block;
        font-size: rem(12px);
        line-height: rem(20px);

        @include media-breakpoint-up(md) {
            color: $medium-gray;
            display: block;
            font-size: rem(10px);
            line-height: rem(12px);
        }

        &::after {
            content: ", ";
            display: inline-block;

            @include media-breakpoint-up(md) {
                content: "";
            }
        }

        &:last-of-type {
            &::after {
                content: "";
            }
        }

        &.item-number {
            display: none;
        }
    }

    .line-item-availability {
        display: none;

        @include media-breakpoint-up(md) {
            line-height: rem(12px);
        }
    }

    .line-item-quantity {
        @include media-breakpoint-up(md) {
            line-height: rem(12px);
        }

        .line-item-pricing-info {
            @include media-breakpoint-up(md) {
                line-height: rem(12px);
            }
        }

        .qty-card-quantity-label,
        .qty-card-quantity-count {
            font-size: rem(12px);
            line-height: rem(20px);
            font-weight: normal;

            @include media-breakpoint-up(md) {
                color: $medium-gray;
                font-size: rem(10px);
                line-height: rem(12px);
            }
        }

        .qty-card-quantity-label {
            &::after {
                content: ":";
                display: inline-block;
            }
        }
    }

    .item-attributes {
        padding-bottom: rem(20px);
        position: relative;
        width: 50%;
    }

    .line-item-total-price {
        width: 40%;

        @include media-breakpoint-up(md) {
            width: 35%;
        }

        .line-item-total-price-label {
            display: none;
        }

        .price {
            font-size: rem(14px);
            line-height: rem(18px);
            font-weight: normal;

            @include media-breakpoint-up(md) {
                font-size: rem(12px);
                line-height: rem(16px);
            }

            .strike-through {
                margin: 0;
            }

            .pricing {
                font-weight: normal;
            }
        }
    }

    .remove-line-item {
        bottom: 0;
        left: 0;
        position: absolute;

        .remove-product {
            background: none;
            border: none;
            color: $medium-gray;
            font-size: rem(12px);
            font-weight: 700;
            line-height: rem(16px);
            margin: 0;
            padding: 0;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .line-item-unit-price,
    .line-item-divider {
        display: none;
    }

    .line-item-promo {
        color: $ps-red;
        font-size: rem(10px);
        font-weight: 500;
        line-height: rem(14px);
    }
}

.product-line-item-details {
    overflow-y: visible;

    .finalMsg {
        font-size: rem(12px);
    }
}

.line-item-priority-fulfillment {
    font-size: $font-size-xs;
    line-height: 1.3333; /* 16px / 12px = 1.3333 */
    font-weight: $font-weight-semibold;
    color: $periwinkle;

    .order-product-summary & {
        font-size: 0.625rem; /* (10px / 16px) = 0.625rem */
        line-height: 1.4; /* line height in unitless value (14px / 10px) */
    }
}
