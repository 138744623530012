@import "../variables";
$copyright-breakpoint: 830px;

footer {
    background-color: #f6f5f3;
    padding-top: 0;
    position: relative;

    .footer-container-padding {
        padding-right: rem(10px);
        padding-left: rem(10px);
    }

    .footer-bottom-container {
        height: 6.25rem;
        @include media-breakpoint-down(sm) {
            height: auto;
            flex-flow: row wrap;
            overflow: hidden;
            margin-right: rem(-15px);
            margin-left: rem(-15px);
            width: auto;
        }
    }

    .country-selector-section {
        width: 33%;
        display: flex;
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }

        .btn {
            line-height: rem(100px);
            color: #fff;
            padding: 0;
            border: 0;
            @include media-breakpoint-down(sm) {
                height: rem(100px);
                font-size: rem(14px);
            }
        }
    }

    .content-asset-section {
        width: 66%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .footer-app-section-styling {
        background-color: #111;
        height: rem(100px);
        @include media-breakpoint-down(sm) {
            height: rem(100px);
            font-size: rem(14px);
        }
    }

    .footer-app-font-sizing {
        font-size: rem(16px);
        font-weight: 500;
        @include media-breakpoint-down(sm) {
            font-weight: 400;
            font-size: rem(14px);
        }
    }

    .footer-link-row {
        background-color: #f6f5f3;
        min-height: rem(934px);
        @include media-breakpoint-up(md) {
            min-height: rem(535px);
        }
    }

    .copyright-font {
        font-style: normal;
        font-weight: 600;
        font-size: rem(12px);
        line-height: rem(16px);
        letter-spacing: -0.25px;
        color: #111;
        padding-right: rem(15px);
        padding-bottom: rem(40px);
    }

    .mobile-copyright-font {
        padding-top: rem(15px);
        font-style: normal;
        font-size: rem(10px);
        line-height: rem(16px);
        letter-spacing: rem(-0.25px);
        color: #111;
        padding-bottom: rem(40px);
        font-weight: 600;
    }

    .responsibility-links-container {
        padding-top: rem(10px);

        .responsibility-links,
        #ot-sdk-btn.ot-sdk-show-settings {
            padding-right: rem(7px);
            font-style: normal;
            font-weight: 500;
            font-size: rem(13px);
            line-height: rem(16px);
            letter-spacing: rem(-0.25px);
            color: #111;
            @include media-breakpoint-down(sm) {
                font-size: rem(10px);
            }
        }

        #ot-sdk-btn.ot-sdk-show-settings {
            padding: 0 0 rem(7px) 0;
            border: 0;
            background: none;

            &:hover {
                background: none;
            }
        }
    }

    .california-message {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: rem(10px);
        line-height: rem(16px);
        letter-spacing: rem(-0.25px);
        color: #6c6c6b;
    }

    .font-weight-500 {
        font-weight: 500;
    }

    .footer-link-category {
        text-transform: capitalize;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(32px);
        margin-bottom: rem(11px);
        @include media-breakpoint-up(md) {
            font-size: rem(16px);
            width: 10.625rem;
        }
    }

    .footer-link {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: rem(14px);
        line-height: rem(22px);
        display: block;
        margin-bottom: rem(11px);
        color: #111;
        @include media-breakpoint-up(md) {
            font-size: rem(16px);
        }
    }

    .footer-link-margin {
        margin-bottom: rem(0);
        padding-top: rem(18px);
    }

    .social-icon {
        padding-right: rem(5px);
    }

    .facebook-icon-padding {
        padding-left: rem(3px);
        padding-right: rem(6px);
    }

    .utility-heading {
        color: $black;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(24px);
        text-align: center;
        text-transform: initial;
        @include media-breakpoint-down(sm) {
            font-size: rem(12px);
            line-height: rem(18px);
        }
    }

    .footer-utility-row {
        background-color: $white;
        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            min-height: rem(120px);
        }

        .mobile-nav-likes-container {
            display: none;
        }
    }

    .utility-div {
        @include media-breakpoint-down(sm) {
            flex: 0 0 auto;
            min-height: rem(120px);
        }
        @include media-breakpoint-up(md) {
            min-height: rem(158px);
            padding-bottom: rem(25px);
        }
    }

    .utility-heading {
        color: $black;
        margin-bottom: rem(6px);
        font-style: normal;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(24px);
    }

    .utility-row-icon {
        padding-top: rem(27px);
        padding-bottom: rem(12.5px);
    }

    .utility-subtext {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: rem(12px);
        line-height: rem(20px);
        text-align: center;
        color: #696969;
        padding-top: rem(5px);
        text-transform: initial;
        @include media-breakpoint-down(sm) {
            padding-top: 0;
            font-size: rem(10px);
            line-height: rem(14px);
            padding-bottom: rem(25px);
        }
    }

    .learn-more-link {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-size: rem(12px);
        line-height: rem(20px);
        color: #111;
        text-transform: uppercase;
        font-weight: 700;
        padding-bottom: rem(26px);

        .learn-more-call-to-action {
            text-decoration: underline;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .email-sign-up-container {
        color: $white;
        background-color: $black;
        margin-right: rem(-15px);
        margin-left: rem(-15px);
        overflow: hidden;
        padding: rem(40px) 0 rem(48px);

        .email-sign-up-row {
            margin-left: rem(15px);
            margin-right: rem(15px);

            .email-sign-up-intro {
                h2 {
                    font-size: rem(32px);
                    letter-spacing: rem(-0.25px);
                    line-height: 36px;
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        display: inline;
                    }
                }

                p {
                    font-size: rem(18px);
                    margin-bottom: 0;
                    padding-top: rem(20px);
                }
            }

            .email-sign-up-form {
                .form-row {
                    margin: 0;
                    padding: rem(30px) 0 0;

                    .input-col {
                        padding: 0;
                        margin-bottom: 0;
                        position: relative;

                        &:first-child {
                            padding-bottom: rem(16px);

                            @include media-breakpoint-up(lg) {
                                margin-right: 1rem;
                                padding-bottom: 0;
                            }
                        }

                        .icon {
                            position: absolute;
                            left: 10px;
                            top: 7px;
                        }

                        .form-control {
                            background-color: $black;
                            border: rem(1px) solid $white;
                            border-radius: rem(3px);
                            color: $white;
                            font-size: $font-size-sm;
                            height: rem(40px);
                            padding: 0 rem(10px) 0 rem(38px);

                            &:focus {
                                background-color: $black;
                                color: $white;
                            }

                            &.is-invalid {
                                &:focus {
                                    box-shadow: none;
                                }
                            }

                            &.mobile {
                                padding-left: rem(34px);
                            }
                        }

                        .form-control::-webkit-input-placeholder {
                            /* WebKit, Blink, Edge */
                            color: $white;
                        }

                        .form-control:-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 */
                            color: $white;
                            opacity: 1;
                        }

                        .form-control::-moz-placeholder {
                            /* Mozilla Firefox 19+ */
                            color: $white;
                            opacity: 1;
                        }

                        .form-control:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: $white;
                        }

                        .form-control::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: $white;
                        }

                        .form-control::placeholder {
                            /* Most modern browsers support this now. */
                            color: $white;
                        }
                    }
                }

                p {
                    &.form-errors {
                        color: $ps-red;
                        display: none;
                        margin-bottom: 0;
                    }

                    &.check-all {
                        font-weight: $font-weight-medium;
                        letter-spacing: rem(-0.24px);
                        line-height: 1;
                        padding-top: rem(30px);
                    }
                }

                .shopping-pref {
                    display: flex;
                    flex-wrap: wrap;

                    .form-check {
                        margin: 0;
                        padding: 0;
                        width: 100%;

                        @include media-breakpoint-up(md) {
                            flex-basis: 0;
                            flex-grow: 0;
                            margin-right: rem(30px);
                        }

                        // ie11 email-sign-up checkboxes
                        @media all and (-ms-high-contrast: $dollar-variable-none),
                            (-ms-high-contrast: $ms-high-contrast-active) {
                            flex-basis: auto;
                            width: auto;
                        }
                    }

                    .checkbox {
                        label {
                            align-items: center;
                            display: inline-flex;
                            cursor: pointer;
                            font-size: $font-size-sm;
                            font-weight: $font-weight-medium;
                            line-height: rem(12px);
                            margin-bottom: 0;
                            text-transform: capitalize;

                            span {
                                margin-left: 0.5rem;
                            }

                            &::before {
                                content: "";
                                display: inline-block;
                                background-color: $white;
                                border: rem(1px) solid $black;
                                border-radius: rem(3px);
                                height: rem(16px);
                                margin: rem(4px) 0;
                                width: rem(16px);
                            }

                            &::after {
                                content: "";
                                background-color: $white;
                                border-bottom: rem(2px) solid $white;
                                border-left: rem(2px) solid $white;
                                height: rem(5px);
                                left: rem(3px);
                                margin-top: rem(6px);
                                position: absolute;
                                top: rem(3px);
                                transform: rotate(-45deg);
                                width: rem(10px);
                            }
                        }

                        input[type="checkbox"] {
                            left: rem(21px);
                            opacity: 0.00000001;
                            top: rem(-10px);
                            width: auto;
                            position: absolute;
                            margin-top: 0.3rem;
                            margin-left: -1.25rem;

                            &:checked ~ label {
                                &::after {
                                    border-color: $black;
                                }
                            }

                            &:focus + label::before {
                                outline: 0;
                            }
                        }
                    }
                }

                .form-terms {
                    padding-top: rem(25px);

                    p {
                        font-size: $font-size-xs;
                        font-weight: $font-weight-light;
                        line-height: rem(16px);

                        a {
                            color: $white;
                            text-decoration: underline;
                        }
                    }
                }

                .btn-sign-up {
                    padding: rem(20px) 0 0;

                    .btn {
                        &.btn-secondary {
                            border-color: $white;
                            border-radius: rem(3px);
                            font-size: $font-size-sm;
                            width: 100%;

                            &:hover {
                                color: $black;
                                background-color: $white;
                                border-color: $white;
                            }
                        }
                    }
                }
            }

            .email-sign-up-success {
                display: none;

                .success-msg {
                    padding-top: rem(30px);

                    label {
                        color: $success;
                        cursor: pointer;
                        font-size: rem(24px);
                        font-weight: $font-weight-medium;
                        line-height: rem(36px);
                        position: relative;

                        span {
                            margin-left: 0.5rem;
                        }

                        &::before {
                            content: "";
                            background-color: $success;
                            border: 1px solid $success;
                            border-radius: rem(3px);
                            display: inline-block;
                            height: rem(20px);
                            margin: 0;
                            width: rem(20px);
                        }

                        &::after {
                            content: "";
                            border-bottom: rem(3px) solid $white;
                            border-left: rem(3px) solid $white;
                            font-size: rem(10px);
                            height: 1ex;
                            left: rem(4px);
                            margin-top: rem(8px);
                            position: absolute;
                            top: rem(4px);
                            transform: rotate(-45deg);
                            width: 2.2ex;
                        }
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                margin-left: rem(45px);
                margin-right: rem(45px);
            }
        }

        // Remove Chrome autofill yellow background
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            -webkit-text-fill-color: $white !important;
            -webkit-box-shadow: 0 0 0 1000px $black inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .california-residents {
        font-weight: 500;
    }

    .mobile-app-height {
        border: 1px #000 solid;
    }

    .sticky-footer-row {
        z-index: 100;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .sticky-footer-privacy-policy-container {
        background-color: rgba(17, 17, 17, 0.85);
        position: absolute;
        bottom: 0;
        z-index: 3;

        .privacy-policy-text {
            font-weight: normal;
            font-size: $font-size-xs;
            line-height: rem(16px);
            letter-spacing: rem(-0.25px);
            @include media-breakpoint-down(sm) {
                color: $white;
                padding-bottom: rem(60px);
            }
        }

        .privacy-policy-close-button {
            width: rem(26px) !important;
        }

        .privacy-policy-link {
            color: $black;
            text-decoration: underline;
            @include media-breakpoint-down(sm) {
                color: $white;
            }
        }

        .privacy-policy-bg {
            @include media-breakpoint-down(sm) {
                background-color: rgba(17, 17, 17, 0.8);
            }
        }
    }

    .bg-none {
        background-color: transparent;
    }

    .pr-30 {
        padding-right: rem(30px);
    }

    .h-26 {
        height: rem(26px);
    }

    .pb-xl-40 {
        @include media-breakpoint-up(xl) {
            padding-bottom: rem(40px);
        }
    }

    .learn-more-call-to-action {
        font-size: 0.75rem;
    }

    .bfx-cc-wrapper {
        z-index: 1 !important;

        .bfx-cc-collapsed {
            padding: 0;
            display: inline;
        }
    }

    .footer-cat-link {
        margin-bottom: 0;
        line-height: 2rem;
    }

    .contact-us-link {
        line-height: 2rem !important;
    }

    .footer-checkout {
        font-size: $font-size-xs;
        padding: rem(30px) rem(10px) rem(25px);

        a,
        #ot-sdk-btn.ot-sdk-show-settings {
            color: $black;
            font-weight: $font-weight-medium;
        }

        .disclosure {
            line-height: rem(16px);
            border-bottom: 1px solid $black;
            padding-bottom: rem(25px);

            a {
                text-decoration: underline;
            }
        }

        .links {
            display: flex;
            flex-wrap: wrap;
            line-height: rem(22px);
            padding-top: rem(25px);

            a,
            #ot-sdk-btn.ot-sdk-show-settings {
                flex: 0 0 100%;
            }

            #ot-sdk-btn.ot-sdk-show-settings {
                padding: 0;
                border: 0;
                background: none;
                font-size: $font-size-xs;
                line-height: rem(22px);

                &:hover {
                    background: none;
                }
            }

            .ps-copyright {
                flex: 0 0 100%;
                font-weight: $font-weight-medium;
                font-size: rem(10px);
                padding-top: rem(50px);

                @media (min-width: $copyright-breakpoint) {
                    flex: none;
                    font-size: $font-size-xs;
                    margin-left: auto;
                    padding-top: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            .disclosure {
                border-bottom: 0;
            }

            .links {
                padding-top: 0;

                a,
                #ot-sdk-btn.ot-sdk-show-settings {
                    flex: none;
                    margin-right: rem(25px);
                }
            }
        }

        @include media-breakpoint-down(lg) {
            padding-bottom: rem(65px);
        }
    }
}

.linc-web-chat {
    opacity: 0;
    transition: opacity 0.3s linear;
    right: rem(-25px) !important;
    bottom: 26% !important;
    transform: translateY(26%);

    &.linc-web-chat-button {
        width: rem(76px) !important;
        height: rem(167px) !important;
    }

    &.linc-web-chat-chat {
        right: rem(-5px) !important;
        bottom: 0 !important;
        transform: none;

        &.linc-web-chat-mobile {
            right: 0 !important;
        }
    }

    &.show-chat {
        opacity: 1;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

#pptou-sticky {
    z-index: 9;
}

[data-is-instore="true"] ~ footer {
    .footer-styled-by {
        display: none;
    }
}
