.info-icon {
    .tooltip {
        @include media-breakpoint-down(sm) {
            margin-left: rem(-160px);
            margin-top: rem(-425px);
            left: 1.5rem;

            &::after {
                left: 96%;
            }
        }
    }
}
