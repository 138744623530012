#chooseBonusProductModal {
    @include media-breakpoint-down(sm) {
        padding-right: 0 !important;
    }

    .swatch-circle {
        border-radius: 0;
        margin-right: 0;

        &.color-value.selected::after {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        z-index: 1;
    }

    .modal-dialog {
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }

    .quick-view-navigation-wrapper {
        bottom: 15px;
        right: 0;
        left: 0;
    }

    .product-details-wrapper {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        width: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.bonus-product-modal-header {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
}

.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: rem(800px);

    .modal-body {
        max-height: rem(650px); /* 450/16 */
        overflow-y: auto;
    }

    .modal-footer {
        border-top: 1px solid $black;
    }
}
