////-----------------------------------------------
/// GLOBAL FUNCTIONS
////-----------------------------------------------

@import "./mixins/input_placeholder";
@import "./mixins/position";

$browserBasePixelSize: 16px;

///	calculateRem - Calculates a proper rem value (usually a font-size) given a pixel value.
///	@param {number} $size - a pixel || verbal attribute: auto, initial, etcetera.
///	@return {number} a rem value based on a default browser font size of 16px
///	@alias rem()

@function calculateRem($size) {
    @if type-of($size) == string or $size == 0 or unit($size) == "rem" {
        @return $size;
    }
    @if unit($size) != "px" {
        @error "The calculateRem function expects a pixel || verbal attribute: auto, initial, etcetera.";
    }
    $remSize: $size / $browserBasePixelSize;
    @return $remSize * 1rem;
}

@function rem($size) {
    @return calculateRem($size);
}

///	calculateEm - Calculates an em value given a px value - mainly used in media queries.
/// @param {number} $size - a pixel || verbal attribute auto, initial, etcetera.
///	@return {number} an em value based on a font size of 16px

@function calculateEm($size) {
    @if type-of($size) == string or $size == 0 or unit($size) == "em" {
        @return $size;
    }
    @if unit($size) != "px" {
        @error "The calculateEm function expects a pixel || verbal attribute: auto, initial, etcetera.";
    }
    $emSize: $size / 16px;
    @return $emSize * 1em;
}

//  Center Elements - Requires Relative or Absolute positioning & parent element requires Relative positioning
//---------------------------------------

@mixin centerElement($elePosition: center, $positioning: absolute) {
    position: $positioning;

    @if $elePosition == center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @if $elePosition == horizontal {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $elePosition == vertical {
        top: 50%;
        transform: translateY(-50%);
    }
}

/// ensure-unit - If a number value is 0, adds 'px' to it.
/// @param {number} $value - if unit value, leave it alone. if unitless 0 value, append 'px'.
/// @return {number} a unit number value

@function ensure-unit($value: null, $unit: "px") {
    $return-value: $value;
    @if $value != null and unit($value) == "" {
        $return-value: #{$value}#{$unit};
    }
    @return $return-value;
}
