//============================================
// Position Mixin
//============================================

// Generate position properties in shorthand form.
//
// @param {List}    $values - List of numbers or `auto`
// @param {List}    $sides - List of sides (`top`, `left`, `bottom`, `right` or `all`)
// @param {String}  $pos - Position keyword or `none`
// @param {Bool}    $auto-overwrite - If `true` all non specified $sides will be set to `auto`
//
// NOTE: `$values` get mapped to `$sides` in the order they are declared, see usage below

//.my-class {
//    @include pos(0 200px, top left, $auto-overwrite: true);
//}

// -> Output

// .my-class {
//   position: absolute;
//   top: 0;
//   left: 200px;
//   right: auto;
//   bottom: auto;
// }
//
// https://gist.github.com/nicolas-cusan/8fc0cef0f466f6ca2ebc

@mixin position($values, $sides, $pos: absolute, $auto-overwrite: false) {

    // Vars
    $positions: absolute, relative, fixed, static;
    $map: (top: null, left: null, right: null, bottom: null);

    // Set everything to auto to overwrite other styles.
    // Edge case but can be useful.
    @if $auto-overwrite {
        $map: ( top: auto, left: auto, right: auto, bottom: auto);
    }

    // Validate `$values` & `$sides` input and warn (input gets rendered though)

    /* stylelint-disable block-no-empty */
    @each $value in $values {
        @if type-of($value) == number or $value == auto {} @else {
            @warn "Invalid amount: #{$value}";
        }
    }

    @each $side in $sides {
        @if index(map-keys($map), $side) or $side == all {} @else {
            @warn "Invalid side: #{$side}";
        }
    }

    // Render & Validate `$pos`
    @if index($positions, $pos) {
        position: $pos;
    } @else if $pos != none {
        @warn "The $pos argument is invalid, use `absolute`, `fixed`, `relative`, `static`, or `none` (invalid value: #{$pos})";
    }

    // Populate the map with `$sides`
    @if $sides == all and length($values) == 1 {
        top: $values;
        left: $values;
        right: $values;
        bottom: $values;
    } @else if length($sides) == length($values) {
        @for $i from 1 through length($sides) {
            $map: map-merge($map, (nth($sides, $i):nth($values, $i)));
        }
    } @else if length($values) == 1 {
        @for $i from 1 through length($sides) {
            $map: map-merge($map, (nth($sides, $i):$values));
        }
    } @else {
        @warn "The number of $values has to match the $sides or be a single value";
    }

    // Render (properties with value `null` don't get rendered)
    @each $key, $val in $map {
        #{$key}: #{$val};
    }
}

// Alias

@mixin pos($values, $sides, $pos: absolute, $auto-overwrite: false) {
    @include position($values, $sides, $pos, $auto-overwrite);
}
