.navbar-attic {
    display: flex;
    width: 100%;
    padding: rem(13px) rem(4px);
    border-bottom: 1px solid $light-gray;

    .navbar-attic-center {
        padding: 0;
        text-align: center;
    }

    .navbar-attic-title {
        font-size: rem(14px);
        line-height: rem(14px);
        font-weight: 500;
    }
}

.home-app {
    padding-top: 4rem;
}

.menu-app-all-categories {
    width: 100%;
    margin-top: rem(20px);
    margin-bottom: rem(70px);
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding-bottom: 20%;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .menu-app-category {
        display: block;
        position: relative;
        padding-bottom: 35%;
        margin-bottom: rem(10px);
        overflow: hidden;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 41.73%, rgba(0, 0, 0, 0.3) 80.8%);
        }
    }

    .menu-app-category-title {
        position: absolute;
        top: rem(20px);
        left: rem(15px);
        z-index: 2;
        color: $white;
    }

    .menu-app-category-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
}

.cellar-nav {
    display: flex;
    justify-content: space-between;
    padding: rem(12px) rem(24px) rem(7px);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    border-top: rem(1px) solid $light-gray;
    background: white;
    list-style: none;

    .mobile-nav-height & {
        border-top: none;
        z-index: 1030;
    }

    .cellar-nav-item {
        margin: 0;
        text-align: center;

        &:hover,
        &.active {
            &.cellar-nav-item--home,
            &.cellar-nav-item--likes,
            &.cellar-nav-item--profile {
                svg path {
                    fill: $black;
                }
            }

            &.cellar-nav-item--bag {
                svg path {
                    stroke: $black;
                }
            }

            .cellar-nav-heading {
                color: $black;
            }
        }
    }

    .cellar-nav-link {
        display: flex;
        flex-direction: column;
    }

    .cellar-nav-icon {
        display: block;
        width: 100%;
        height: rem(22px);
        margin-bottom: rem(5px);

        svg {
            max-height: 100%;
        }
    }

    .cellar-nav-heading {
        font-size: rem(10px);
        line-height: rem(15px);
        font-weight: 400;
        color: $dark-gray;
    }
}
