//
// Headings
//

h1,
.h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -0.75px;
    margin: 0;
}

h2,
.h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -0.5px;
    margin: 0;
}

h3,
.h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.25px;
    margin: 0;
}

h4,
.h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
}

h5,
.h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

h6,
.h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
}
