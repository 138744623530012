.container {
    .breadcrumb {
        padding: 0;
        margin: 0;
        border: 0;

        .breadcrumb-item {
            margin: 0;

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                &,
                a {
                    color: $body-color;
                }
            }
            // The separator between breadcrumbs (by default, a forward-slash: "/")
            + .breadcrumb-item {
                padding-left: rem(4px);

                &::before {
                    display: inline-block; // Suppress underlining of the separator in modern browsers
                    padding-right: 0;
                    content: "/";
                    color: $primary;
                }
            }
        }

        a {
            color: $body-color;
            font-size: $breadcrumb-font-size;
            line-height: $breadcrumb-line-height;
        }
    }

    &.alt-text-color .breadcrumb {
        .breadcrumb-item {
            a,
            .breadcrumb-item::before {
                color: $secondary !important;
            }
        }
    }
}

.producthits-breadcrumbs-container {
    color: $white;
    position: absolute;
    margin-top: 18px;

    a {
        color: $white !important;
    }

    .breadcrumb {
        a:not(.last) {
            color: $white !important;
        }

        .breadcrumb-item + .breadcrumb-item::before {
            color: $white;
        }
    }
}

.breadcrumbs-wrapper {
    z-index: 1;
    font-family: $base-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px !important;
    line-height: 18px;

    a {
        text-decoration: none !important;
        color: $black;
    }
}
