
.form-group {
    &.required label {
        &.form-control-label {
            &::before {
                content: "";
            }

            &::after {
                content: "*";
                color: $ps-dusty-gray;
            }
        }
    }
}

@media all and (-ms-high-contrast: $dollar-variable-none), (-ms-high-contrast: $ms-high-contrast-active) {
    .custom-select {
        background-size: auto;
    }
}

// Float Labels
%float-label-field {
    position: relative;
}

%float-label-unfocused {
    position: absolute;
    top: $input-float-label-pos-top;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0;
    padding: 0 $input-padding-x;
    height: auto;
    width: auto;
    font-weight: $input-float-label-font-weight;
    color: $input-float-label-color-floated;
    font-size: $input-float-label-font-size;
    line-height: initial;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
    transition: $input-float-label-transition;
}

%float-label-focused {
    // Remove label from placeholder position, move it to the top of the field border
    top: $input-float-label-pos-top-floated;
    font-weight: $input-float-label-font-weight-floated;
    color: $input-float-label-color-floated;
    font-size: $input-float-label-font-size-floated;
    overflow: visible;
    height: auto;
}

%float-label-disabled {
    color: $custom-control-label-disabled-color;
}

.float-label {
    @extend %float-label-field;

    .form-control {
        &:not(.full-border) {
            border: 0;
            border-bottom: 0.0625rem solid $input-border-color;

            &:focus ~ label,
            &.text-entered ~ label {
                padding-left: 0;
            }
        }

        padding: rem(24px) rem(10px) 0;
    }

    input,
    textarea {
        ~ label {
            @extend %float-label-unfocused;
        }

        &:focus ~ label,
        &.text-entered ~ label {
            @extend %float-label-focused;
        }

        &.disabled ~ label,
        &[disabled] ~ label,
        fieldset[disabled] & ~ label {
            @extend %float-label-disabled;
        }

        &[placeholder] {
            &:not(:focus) {
                // Do not combine the following 3 selectors into one. Will not work in MSEdge.
                &::-webkit-input-placeholder {
                    color: $trans;
                }

                &::-moz-placeholder {
                    color: $trans;
                }

                &:-ms-input-placeholder {
                    color: $trans;
                }
            }

            &:focus,
            &.text-entered {
                &.placeholder-center {
                    text-align: left;
                }
                // Do not combine the following 3 selectors into one. Will not work in MSEdge.
                &::-webkit-input-placeholder {
                    color: $input-placeholder-color;
                    -webkit-transition: $input-placeholder-transition;
                }

                &::-moz-placeholder {
                    color: $input-placeholder-color;
                    transition: $input-placeholder-transition;
                }

                &:-ms-input-placeholder {
                    color: $input-placeholder-color;
                    transition: $input-placeholder-transition;
                }
            }
        }
    }

    select {
        height: $input-height;
        background-position: center right 10px;

        &,
        &:focus {
            border: rem(1px) solid $input-border-color;
        }

        @extend %float-label-field;

        ~ label {
            pointer-events: none;
            @extend %float-label-unfocused;
        }

        &.text-entered ~ label {
            @extend %float-label-focused;
        }

        &.disabled ~ label,
        &[disabled] ~ label,
        fieldset[disabled] & ~ label {
            @extend %float-label-disabled;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
