.custom-checkbox {
    .custom-control-label {
        &::before {
            background: $trans;
        }
    }

    .custom-control-input {
        &:checked ~ .custom-control-label {
            &::after {
                background-image: $svg-checkbox-check-mark;
                top: rem(3px);
            }
        }
    }

    label {
        font-size: rem(12px);
        line-height: rem(24px);
    }
}

.tooltip-span {
    width: rem(13px);
    height: rem(13px);
    background-color: $primary;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    top: rem(-2px);

    &::before {
        font-size: rem(10px);
        line-height: rem(9px);
        display: block;
        content: "?";
        color: $secondary;
        @include centerElement(center, absolute);
    }

    &.light {
        background-color: $secondary;

        &::before {
            color: $primary;
        }
    }
}

.btn {
    font-weight: 600;
    text-decoration: none !important;
}
