@import "../utilities/swatch";
@import "~base/components/productCommon";
@import "~base/components/quickView";

.product-tile {
    @include media-breakpoint-down(md) {
        min-height: 23.4375em;
    }

    @include media-breakpoint-down(sm) {
        min-height: 19.6875em;
    }

    @include media-breakpoint-down(xs) {
        min-height: 13.4375em;
    }

    border: 0;
    margin-bottom: 0;

    .text-product-tag {
        min-height: 22px;
        color: $ps-blue;

        &--new {
            color: $periwinkle;
        }

        &--coming-soon,
        &--sold-out {
            color: $dark-gray;
        }
    }

    .tile-body {
        padding-bottom: rem(30px);
        padding-top: rem(14px);

        @include media-breakpoint-down(sm) {
            padding: rem(12px) rem(4px) rem(21px) rem(4px);
        }

        .color-swatches {
            min-height: 2.25em;

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }
        }

        .price {
            font-size: rem(14px);
            line-height: rem(16px);
            margin-bottom: 0;
            padding: 8px 0;

            @include media-breakpoint-up(md) {
                line-height: rem(18px);
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .pdp-link {
            position: relative;
            line-height: $product-name-lh;

            a {
                font-size: rem(14px);
                font-weight: 500;
                line-height: 16px;
                text-decoration: none;
                letter-spacing: rem(-0.25px);
                max-width: rem(147px);
                color: $black;

                @include media-breakpoint-up(md) {
                    line-height: 18px;
                    max-width: rem(238px);
                }
            }
        }

        .ratings {
            font-size: 0.9em;
        }

        .swatchColor {
            padding: 0.3rem 0 0;
        }
    }

    .image-container {
        position: relative;
        overflow: hidden;

        .quickview {
            position: absolute;
            bottom: 1rem;
            right: 1rem;

            i {
                &.fa-expand {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.fa-circle {
                    color: rgba(255, 255, 255, 0.7);
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                }
            }
        }

        a {
            display: block;
        }
    }

    .swatches {
        a {
            text-decoration: none;
            color: $primary;
            line-height: 15px;
            vertical-align: middle;
            background-color: transparent;
            border: none;
            padding: 0 0.125rem;
        }
    }

    .swatch-circle {
        // $white will be replaced by color image background via Javascript
        @include swatch(24px, $white);

        &:not(.swatch-circle-white) {
            border: 0;
        }

        &.selected {
            outline: 1px solid $white;
            outline-offset: -2px;
            border: 1px solid $primary;
        }

        margin: 0;
    }

    .tile-image-container {
        position: relative;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .small {
        font-size: 10px;
    }
}

.homepage-slot ~ .slot-product-tile-carousel-container {
    .slick-list {
        padding-left: 15px;
    }

    .slick-prev {
        margin-left: 3rem;
        top: 200px;
    }

    .slick-next {
        top: 200px;
        right: 1px;
    }
}

.carousel-navigation {
    height: rem(3px);
    width: 100%;
    background-color: $gridTileSliderNavBarBackground;
    cursor: pointer;
    border-radius: rem(15px);
    list-style: none;
    padding: 0;

    li {
        height: 100%;
        flex: 2 1 auto;
        border-radius: rem(3px);
        transition: $transition-base;
        margin: 0;

        &.active,
        &:hover {
            background-color: $gridTileSliderNavItemBackground;
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;

        &:disabled {
            display: none;
        }
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

body {
    .add-to-cart-messages {
        z-index: 1050;
    }
}

.quickshop-container {
    position: absolute;
    bottom: 0;

    .add-to-cart-global {
        width: 100%;
    }

    .qs-disabled {
        opacity: 1 !important;
    }

    .qs-disabled:hover {
        color: #212529;
        background-color: #e2e6ea;
    }
}

.quick-shop-attributes {
    background: rgba(255, 255, 255, 0.94);
}

.btn-light-size {
    background-color: $light-gray;
}

.atr-size {
    font-size: rem(10px);
    font-weight: 700;
    line-height: 18px;
    margin: 0;
}

.btn.js-quick-shop-attribute {
    padding: rem(10px) 0;
}

.product-image.my-bag {
    height: 128px;
    width: 83px;
    @include media-breakpoint-up(md) {
        height: 152px;
        width: 99px;
    }
}

.quick-container {
    font-weight: 600;
}

.quick-view-select-error {
    color: $danger;
    width: fit-content;
    font-size: 10px;
}

.suggestions {
    .pdp-link {
        a {
            max-width: rem(187px) !important;
        }
    }
}

.ais-InfiniteHits-item {
    .product-tile {
        .image-container {
            .quickview {
                display: none;
            }
        }
    }
}

.js-recommendation-carousel {
    .product-tile {
        .tile-body {
            .pdp-link {
                a {
                    font-weight: $font-weight-normal;
                }
            }

            .price {
                .sales.text-product-tile-price {
                    .value.bfx-price.bfx-sale-price {
                        color: $black;
                    }
                }
            }
        }
    }
}
