.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.g_wrp {
    margin: 0 auto;
    position: static;
    max-width: 980px;
    box-sizing: border-box;
    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}

.hidden {
    display: none !important;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

.pos-abs {
    position: absolute;
}

.pos-rel {
    position: relative;
}

.zi1 {
    z-index: 1;
}

.zi2 {
    z-index: 2;
}

.zi3 {
    z-index: 3;
}

.zi4 {
    z-index: 4;
}

.zi5 {
    z-index: 5;
}

.zi6 {
    z-index: 6;
}

.red {
    color: $red;
}

.cover-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

img.full {
    width: 100%;
    height: auto;
    display: block;
}

.content-d {
    display: none !important;
    @include media-breakpoint-up(md) {
        display: block !important;
    }
}

.content-m {
    display: block !important;
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}
