.veil {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
}

$spinner-width: 50px;
$spinner-height: 10px;

.spinner {
    width: $spinner-width;
    height: $spinner-height;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $spinner-height / 2 * -1;
    margin-left: $spinner-width / 2 * -1;
    content: "";

    .ellipse {
        animation: loading-spinner 1s linear infinite;

        &:nth-child(1) {
            animation-delay: 0.1s;
        }

        &:nth-child(2) {
            animation-delay: 0.4s;
        }

        &:nth-child(3) {
            animation-delay: 0.8s;
        }
    }

    @keyframes loading-spinner {
        0% {
            opacity: 0.25;
        }

        100% {
            opacity: 1;
        }
    }
}

.grid-spinner {
    width: $spinner-width;
    margin: 0 auto;
    padding: rem(10px) 0;

    .ellipse {
        animation: loading-spinner 1s linear infinite;

        &:nth-child(1) {
            animation-delay: 0.1s;
        }

        &:nth-child(2) {
            animation-delay: 0.4s;
        }

        &:nth-child(3) {
            animation-delay: 0.8s;
        }
    }

    @keyframes loading-spinner {
        0% {
            opacity: 0.25;
        }

        100% {
            opacity: 1;
        }
    }
}
