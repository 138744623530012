body {
    overscroll-behavior-y: none;
}

header {
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.modal-background {
    z-index: $zindex-modal-mask;
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url("../images/storelocator.jpg");
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $ps-red;
}

.card-header-custom {
    font-size: rem(16px);
    margin-bottom: 0;
}

#mobileMiniCartModal {
    .item-image {
        @include media-breakpoint-down(sm) {
            height: 100% !important;
            min-height: 100% !important;

            .item-attributes {
                padding-left: 10px;
            }
        }
    }
}

.brand {
    height: 3.813rem;

    img {
        @include media-breakpoint-down(md) {
            width: rem(134px) !important;
            max-width: rem(134px);
        }
    }
}

.icon-height {
    height: rem(62px) !important;
}

.header-height-App {
    height: rem(51px) !important;
}

.text-poppins {
    font-family: "Poppins", sans-serif;
}

#maincontent {
    background-color: $white;
}

.position-sticky {
    position: sticky;
}

.visuallyhidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}

.selected-fill {
    fill: $ps-blue !important;
}

.selected-categories {
    margin-bottom: 0;

    span:not(:last-of-type) {
        &::after {
            content: ", ";
            display: inline;
        }
    }
}

.link {
    cursor: pointer;
}

.page-header-no-menu-logo {
    width: rem(226px);

    @include media-breakpoint-down(sm) {
        width: rem(134px);
    }
}

.zyler-try-on-cta {
    display: flex;
    align-items: center;
    border: 1px #78797a solid !important;
    border-radius: 2rem;
    margin: 0;
    background-color: #78797a !important;
    cursor: pointer !important;
    position: absolute;
    color: $white;

    // &:hover {
    //   background-color: #f0eff0;
    // }
    .zylerCamera {
        width: 16px !important;
        height: 13px !important;
        display: inline-block !important;
        background: url("../../images/zyler-camera.svg") center no-repeat;
        border: 0;
    }
}

.plpZyler {
    top: 0;
    left: 0;
    margin-left: 8px !important;
    margin-top: 14px !important;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    padding: 0.2rem 0.4rem !important;
}
