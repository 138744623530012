@import "~pacsun/variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

// Likes Button

.btn-likes {
    cursor: pointer;
    z-index: 1;

    .heart-icon {
        width: rem(15px);
        height: rem(14px);
        display: block;
        transition: opacity 0.05s linear;
        background-image: $svg-heart-black;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        background-color: transparent;
        position: relative;
        z-index: 1;

        [data-has-transparent-nav="true"][data-action="Home-Show"] &,
        [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
            background-image: $svg-heart-white;

            &.is-black {
                background-image: $svg-heart-black !important;
            }
        }

        @include media-breakpoint-up(md) {
            width: rem(18px);
            height: rem(16px);
        }

        &.selected {
            background-image: $svg-heart-selected-black;
            color: $danger;
        }

        &.selected.position-relative {
            background-image: $svg-heart-selected-red;
        }

        .likes-count {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);

            /* Mobile iOS Positioning */
            $min-resolution: 0.001dpcm;
            @media not all and (min-resolution: $min-resolution) {
                @supports (-webkit-appearance: none) {
                    @supports (-webkit-touch-callout: none) {
                        @include media-breakpoint-down(md) {
                            left: 33% !important;
                        }
                    }
                }
            }

            .product-detail & {
                display: block !important;
            }
        }
    }
}

// Likes Header
header {
    .btn-likes {
        .heart-icon {
            width: 17px;
            height: 17px;
        }

        &.hasLikes .heart-icon {
            background-image: $svg-heart-selected-red;
        }
    }
}

// Likes PDP
.recommendations-container,
.shop-the-look-container {
    .btn-likes {
        top: rem(2px);
    }
}

// Likes Product tiles
.product-tile {
    .btn-likes {
        @include pos(8px 8px, top right, $auto-overwrite: true);

        .heart-icon {
            opacity: 0;
        }
    }
}
// Likes Search Suggestions
.suggestions {
    .btn-likes {
        .heart-icon {
            background-color: transparent;
        }
    }
}

.product-details-wrapper {
    .btn-likes {
        @include pos(7px 0, top right, $auto-overwrite: true);
        @include media-breakpoint-up(lg) {
            @include pos(12px 0, top right, $auto-overwrite: true);
        }

        .heart-icon {
            width: rem(16px);
            height: rem(16px);
        }
    }
}

// Likes General
.likes-container {
    padding: rem(40px) rem(5px) 0;
    overflow: hidden;

    .likes-signin-btn-container {
        @include media-breakpoint-down(md) {
            padding-bottom: 25px;
            padding-top: 20px;
        }
    }

    .likes-signin-btn-container {
        @include media-breakpoint-down(sm) {
            order: 1;
        }
    }

    @include media-breakpoint-up(md) {
        padding: rem(40px) rem(15px) 0;
    }

    .likes-description {
        margin-top: 40px;
        margin-bottom: 140px;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 3px;
        }
    }

    .likes-title {
        font-size: rem(32px);
        font-weight: 500;
        line-height: rem(36px);
        letter-spacing: -0.5px;
        margin: 0 0 rem(18px);

        @include media-breakpoint-up(md) {
            font-size: rem(44px);
            line-height: rem(48px);
            letter-spacing: -0.75px;
        }
    }

    p {
        color: $medium-gray;
        line-height: rem(22px);

        @include media-breakpoint-up(md) {
            font-size: rem(24px);
            line-height: rem(28px);
        }
    }

    .sign-in-btn {
        @include media-breakpoint-up(md) {
            min-width: rem(135px);
            margin-top: 0.125rem !important;
        }
    }
}

// Likes Carousel
.likes-category-slot {
    margin: rem(15px) 0 rem(15px);

    @include media-breakpoint-up(md) {
        margin: 0 0 rem(50px);
    }

    .likes-carousel {
        .item-tile {
            padding: 0 rem(8px);
            position: relative;

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            h4 {
                color: $white;
                font-size: rem(32px);
                font-weight: 500;
                line-height: rem(36px);
                letter-spacing: -0.25px;
                left: rem(23px);
                position: absolute;
                top: rem(15px);

                @include media-breakpoint-up(md) {
                    bottom: rem(92px);
                    font-size: rem(38px);
                    letter-spacing: -0.5px;
                    line-height: rem(42px);
                    left: rem(30px);
                    top: auto;
                }
            }

            .btn {
                bottom: rem(15px);
                left: rem(23px);
                position: absolute;

                @include media-breakpoint-up(md) {
                    bottom: rem(30px);
                    left: rem(30px);
                }
            }
        }
    }
}

// Likes Product
.likes-products {
    margin: rem(12px) 0 0;

    @include media-breakpoint-up(md) {
        margin: rem(10px) 0 0;
    }

    .product {
        flex: 0 0 50%;
        padding: 0 rem(8px);
        max-width: 50%;

        @include media-breakpoint-up(md) {
            flex: 0 0 25%;
            padding: 0 rem(10px);
            max-width: 25%;
        }

        .btn-likes {
            display: none;
        }

        .remove-from-likes {
            cursor: pointer;
            font-size: rem(10px);
            line-height: rem(15px);
            text-transform: uppercase;
            text-decoration: underline;

            @include media-breakpoint-up(md) {
                font-size: rem(12px);
                line-height: rem(16px);
            }

            &:hover {
                text-decoration: none;
            }
        }

        .colors + .remove-from-likes {
            margin-top: rem(5px);
        }

        .promo-message {
            color: $ps-red;
        }
    }
}

// likes-category-slot
.likes-recommendations {
    h2 {
        font-size: rem(24px);
        line-height: rem(28px);

        @include media-breakpoint-up(md) {
            font-size: rem(32px);
            line-height: rem(36px);
            margin: 0 0 rem(30px);
        }
    }

    .slot-product-tile-carousel-container {
        padding-top: 15px !important;

        @include media-breakpoint-up(md) {
            padding-top: 0 !important;
        }
    }

    .callout-msg-product-tile-carousel-container {
        margin-top: 0 !important;
        padding-left: 20px !important;

        @include media-breakpoint-up(xs) {
            padding-left: 20px !important;
        }
    }
}
