@mixin aspect-ratio($arglist...) {
    $map: keywords($arglist);
    $height: map-get($map, height) or nth-value($arglist, 2);
    $width: map-get($map, width) or nth-value($arglist, 1);
    $ratio: map-get($map, ratio) or if($width and $height, $width/$height, nth-value($arglist, 1)) or 1;
    $padding: 1 / $ratio * 100%;

    &::before,
    &::after {
        content: "";
        display: table;
        clear: both;
        width: 1px;
        margin-left: -1px;
    }

    &::before {
        float: left;
        padding-bottom: $padding;
    }
}

// Helper function: return null rather than throwing an error if index is outside list range.

@function nth-value($list, $index) {
    @return if(length($list) >= $index, nth($list, $index), null);
}
