@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: "./" !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-size: rem(7px) !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: rem(40px);
    width: rem(40px);
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background-position: center;
    background-color: $slick-arrow-bg-color;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;

    &:hover,
    &:focus {
        outline: none;
        background-color: $slick-arrow-bg-color-hover;
        color: transparent;

        &::before {
            opacity: $slick-opacity-on-hover;
        }
    }

    &.slick-disabled::before {
        opacity: $slick-opacity-not-active;
    }

    &::before {
        font-size: rem(20px);
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-arrow.slick-disabled {
    display: none !important;
}

.slick-prev {
    left: 0;
    background-image: $svg-slider-arrow-left;

    [dir="rtl"] & {
        left: auto;
        right: 0;
    }
}

.slick-next {
    right: 0;
    background-image: $svg-slider-arrow-right;

    [dir="rtl"] & {
        left: 0;
        right: auto;
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: rem(7px);
        width: rem(7px);
        margin: 0 2px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: rem(7px);
            width: rem(7px);
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: rem(2px);
            cursor: pointer;

            &:hover,
            &:focus {
                color: $slick-dot-color-hover;
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                    background-color: $slick-dot-color-hover;
                }
            }

            &:focus {
                color: $slick-dot-color-active;
            }

            &::before {
                border: rem(1px) solid $slick-dot-color-active;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                height: rem(7px);
                width: rem(7px);
                font-size: $slick-dot-size;
                line-height: rem(67px);
                text-align: center;
                background-color: transparent;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button::before {
            color: $slick-dot-color-active;
            background-color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

.style-dark {
    li {
        &.slick-active button::before {
            color: $slick-dot-color-active-alt;
            background-color: $slick-dot-color-active-alt;
            opacity: $slick-opacity-default;
        }

        button {
            &::before {
                border: rem(1px) solid $slick-dot-color-active-alt;
            }

            &:hover,
            &:focus {
                color: $slick-dot-color-hover-alt;
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                    background-color: $slick-dot-color-hover-alt;
                }
            }
        }
    }
}
