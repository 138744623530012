$breakpoint-XXL: 1400px;

.header-b-search-modal,
.header-app-search-modal {
    width: 100vw;
    height: 100vh;

    @include media-breakpoint-down(sm) {
        z-index: 5;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .site-search {
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: rem(85px) rem(150px) rem(40px) rem(150px);
        }

        .search-bar {
            @include media-breakpoint-only(md) {
                padding: 0 rem(75px);
            }

            @include media-breakpoint-up(lg) {
                padding: 0 rem(150px);
            }

            @include media-breakpoint-down(sm) {
                padding: 0 rem(4px);
            }

            .search-icon {
                background-color: #f6f5f3;
                position: absolute;
                right: rem(144px);
                bottom: rem(-44px);

                @include media-breakpoint-down(sm) {
                    right: 0;
                    bottom: rem(11px);
                }

                @include media-breakpoint-only(md) {
                    right: rem(67px);
                }
            }

            .modal-search-button {
                height: rem(25px);
                width: rem(25px);
                background-color: #f6f5f3;
            }
        }

        .reset-button {
            top: 6.5625em;
            right: 12em;

            @include media-breakpoint-only(md) {
                right: 7em;
            }

            @include media-breakpoint-down(sm) {
                top: 0.25rem !important;
                right: 3.25em !important;
            }
        }
    }

    .modal-header {
        background-color: #f6f5f3;
        min-height: 60px;
        position: relative;

        .close {
            opacity: 1;
            position: absolute;
            right: rem(32px);
            z-index: 6;

            img {
                padding-bottom: rem(20px);
            }

            @include media-breakpoint-down(sm) {
                top: rem(70px);
            }
        }

        .color-black {
            color: $black;
        }
    }

    .modal-body {
        background-color: #f6f5f3;
        padding-top: 75px;

        .search-field {
            background-color: #f6f5f3;
            border: 0;
            font-weight: 500;
            font-size: rem(38px);
            line-height: rem(44px);
            letter-spacing: rem(-0.75px);
            color: #949494;
            border-bottom: rem(1px) solid black;
            margin: 0 auto;
            padding: 0;

            @include media-breakpoint-down(sm) {
                font-size: rem(24px);
                line-height: rem(24px);
            }
        }
    }

    .modal-dialog {
        height: 100%;
        max-width: 100vw;
        margin: auto;
    }

    .modal-content {
        width: 100vw;
        background-color: #f6f5f3;
        border: 0;

        @include media-breakpoint-up(md) {
            height: 100vh;
        }
    }

    .modal-footer {
        background-color: #f6f5f3;
        border: 0;

        @include media-breakpoint-only(md) {
            padding: 0 rem(75px);
        }

        @include media-breakpoint-up(lg) {
            padding: 0 rem(150px) rem(40px);
        }

        @include media-breakpoint-only(sm) {
            padding: 0 rem(20px);
        }

        @include media-breakpoint-down(sm) {
            min-height: rem(1100px);
            padding-left: 0;
            padding-right: 0;

            .items {
                padding-left: 2rem;
                padding-right: 1rem;
            }

            .header {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .trends-cards {
                .flyout-title {
                    padding-left: rem(5px);
                }
            }

            .new-links {
                padding: 0 rem(8px);

                .flyout-title {
                    padding-left: rem(12px);
                }

                .d-md-flex {
                    padding-left: 0.75rem;
                }
            }

            .search-content-wrapper {
                .container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .mobile-search-img-row {
            margin-left: -0.9rem;
            margin-right: rem(-43px);
        }
    }

    .paddingright {
        padding-right: 15px;

        @include media-breakpoint-up(md) {
            padding-right: 25px;
        }
    }
}

.header-b-mobile-search-link {
    border-top: 1px solid $black;
    height: 40px;

    [data-has-transparent-nav="true"][data-action="Home-Show"] &,
    [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;

        &.is-black {
            border-top: 1px solid $black;
            border-bottom: none;
        }
    }
}

.header-app-search-modal {
    @include media-breakpoint-down(sm) {
        top: rem(50px);
    }
}

#search-modal {
    .modal-content {
        width: 100%;
        overflow-y: auto;
    }
}

.modal-backdrop {
    z-index: 4;
    @include media-breakpoint-down(sm) {
        top: rem(58px);
    }

    .body-app & {
        @include media-breakpoint-down(sm) {
            top: rem(52px);
        }
    }
}

.bg-off-white {
    background-color: #f6f5f3 !important;
}

.main-menu {
    @include media-breakpoint-down(sm) {
        z-index: 1050;
    }
}

.search-content-wrapper {

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        min-height: rem(920px);
        overflow-y: auto;
        overflow-x: hidden;

        .flyout-title {
            font-weight: 500;
            font-size: 1rem;
            padding: 10px 0;
        }
    }

    .top-title,
    .bottom-title {
        font-weight: 500;
        font-size: 1rem;
        padding-bottom: 10px;
    }

    .bottom-title {
        padding-top: 30px;
    }

    .flyout-link {
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(22px);
        padding-top: rem(5px);
        color: $black;
    }

    .flyout-button {
        width: 100%;
        background-color: white;
        border: 1px solid black;
        padding: 5px 0;
    }
}

.hover-500:hover {
    font-weight: 500;
}

.search-img-row {
    overflow-x: auto;
    overflow-y: hidden;
}

.suggestions-wrapper {
    position: absolute;
    display: none;
    border-top: 1px solid $black;
    width: 100vw;
    background-color: $white;
    @include media-breakpoint-up(lg) {
        top: rem(47px);
        right: rem(-160px);
        border: 0;
    }
    @include media-breakpoint-down(md) {
        position: fixed;
        overflow-y: auto;
        z-index: 4;
        top: rem(151px);
        padding-bottom: 2rem;
        margin: 0;
        bottom: 0;
        //width: unset;
        max-width: unset;
        border-top: none;
    }
}

.algolia-suggestions-wrapper {
    @include media-breakpoint-up(lg) {
        top: initial !important;
        right: initial !important;
    }
}

.suggestions {
    width: 100%;
    position: relative;
    border: none;
    background-color: $white;

    #search-results {
        @include media-breakpoint-up(md) {
            max-width: 100%;
            margin: 0 auto;
            max-height: 100%;
            overflow-y: hidden;
        }
        @media (min-width: $breakpoint-XXL) {
            max-width: 77%;
        }
    }

    .header {
        border-top: 0 !important;
        padding-top: 0 !important;
        @include media-breakpoint-down(sm) {
            padding-bottom: rem(5px);
        }
    }

    .section-heading {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(22px);
        color: $black;
        @include media-breakpoint-down(sm) {
            padding-top: 1em;
        }
    }

    .product-previews {
        margin: 0;
        list-style-type: none;
    }

    // .view-all {
    //     text-transform: uppercase;
    //     font-size: rem(12px);
    //     line-height: rem(40px);
    //     background-color: $black;
    //     text-align: center;
    //     color: $white;
    //     @include media-breakpoint-down(sm) {
    //         padding-right: 0;
    //     }
    // }

    .itemsList {
        list-style: none;
        margin: 0;
    }

    .item {
        padding: 0;
        margin: 0 0 8px;

        .name {
            margin-top: rem(8px);
            font-weight: normal;
            font-size: rem(16px);
            line-height: rem(18px);
            color: $black;
        }
    }

    .pdp-link {
        .link {
            font-size: rem(16px);
            line-height: rem(18px);
            font-weight: normal;
            letter-spacing: rem(-0.25px);
            color: $black;
        }
    }

    .price {
        font-weight: normal;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(-0.35px);
        color: $black;
    }

    .tile-body {
        padding-bottom: 0;
    }

    .product-tile {
        min-height: 0 !important;
    }

    .product-section {
        .item {
            @include media-breakpoint-up(md) {
                padding: 0 0 rem(10px) rem(20px);
            }

            @include media-breakpoint-down(sm) {
                width: 48%;
                -ms-flex: none;
                flex: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .product-heading-padding {
            padding-left: 0.9rem;
        }
    }

    .search-section-padding {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}

.reset-button {
    display: none;
}

.header-b-search-modal {
    padding-right: 0 !important;

    .header-b-search-logo {
        width: 14.8125rem;
    }
}
