@import "productCard";

.minicart {
    margin: 0;
    vertical-align: top;
    border: 0;

    @include media-breakpoint-down(sm) {
        margin-right: rem(32px) !important;
    }

    .mini-cart-popover {
        font-family: $base-font-family;
    }

    .line-item-promo {
        color: $danger;
    }

    .promo-message.text-promotion {
        color: $danger;
    }

    .mini-cart-padding-top {
        padding-top: 1.25rem;
        background-color: transparent;
    }

    .remove-line-item {
        margin-top: rem(4px);

        .remove-btn {
            background-color: $ps-beige;
        }
    }

    .minicart-quantity {
        width: rem(14px);
        height: rem(14px);
        background-color: $light-gray;
        top: rem(-1px);
        right: rem(-6px);
        font-family: $base-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: rem(10px);
        line-height: rem(15px);
        text-transform: uppercase;
        color: $black;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        position: absolute;
    }

    .minicart-quantity.invert {
        background-color: $black;
        color: $white;
    }

    .cart-w-quantity {
        width: rem(20.28px);
        margin-right: rem(-14px);
        display: block;
        padding-bottom: rem(2px);

        .cart-w-quantity-icon {
            margin-right: rem(-4px);
        }
    }

    /* Firefox Only */
    @supports (-moz-appearance: meterbar) and (display: flex) {
        .cart-w-quantity {
            margin-right: 0;
        }
    }

    /* Safari Only */
    /* stylelint-disable */
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            .cart-w-quantity {
                margin-right: 0;

                .cart-w-quantity-icon {
                    margin-right: 0;
                }
            }
        }
    }

    .minicart-icon {
        font-size: 1.5em;
    }

    a.minicart-link {
        display: block;
        width: rem(16px);
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }

    .desktop-mini-cart.container.cart {
        border: none;
        padding-top: 0;
        width: 100%;
        background-color: $ps-beige;
        overflow-y: auto;
        padding-bottom: rem(150px);
        -webkit-box-shadow: rem(1px) rem(14px) rem(15px) rem(4px) rgba(0, 0, 0, 0.34);
        box-shadow: rem(1px) rem(14px) rem(15px) rem(4px) rgba(0, 0, 0, 0.34);

        .product-summary {
            max-height: 200%;
            overflow-y: hidden;
        }

        .px-20 {
            padding-left: rem(20px);
            padding-right: rem(20px);
        }

        .minicart-header {
            height: rem(68px);
            background-color: $black;
            top: 0;
            margin-bottom: rem(25px);

            .minicart-title {
                font-weight: 500;
                font-size: rem(24px);
                line-height: rem(28px);
                letter-spacing: rem(-0.25px);
            }

            .view-bag {
                text-align: right;

                a {
                    &.view-bag-link {
                        color: $white;
                        font-size: $font-size-xs;
                        font-weight: $font-weight-medium;
                        text-decoration: underline;
                    }
                }

                .close {
                    opacity: 1;
                }
            }
        }

        .card {
            border: 0;
            border-bottom: rem(1px) solid #d4d4d480;
            margin-bottom: 0;

            .card-body {
                background-color: $ps-beige;

                .bonus-embedded-product-container {
                    overflow: hidden;
                    -ms-overflow-style: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .item-image {
                        height: 100% !important;
                    }

                    .mini-cart-line-item-qty {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .line-item-total-price-container {
                        padding-top: rem(99px);

                        .line-item-total-price {
                            margin-right: rem(3px);
                        }
                    }

                    .bonus-product-header {
                        border-top: rem(1px) solid $light-gray;
                        margin-top: rem(9px);
                        font-size: rem(12px);
                    }

                    .mini-cart-line-item-qty {
                        padding-top: rem(43px);
                        padding-left: rem(4px);
                    }
                }

                .custom-select {
                    width: rem(55px);
                    border: rem(1px) solid $light-gray;
                    margin-left: rem(4px);
                    background-color: $ps-beige;
                    padding-left: rem(5px);
                    background-position: 92%;
                }
            }

            .line-item-name {
                font-family: $base-font-family;
                font-style: normal;
                font-weight: normal;
                font-size: rem(14px);
                line-height: rem(20px);
                letter-spacing: -0.0025em;
                text-transform: capitalize;
                word-wrap: break-word;
            }

            .line-item-attributes {
                font-style: normal;
                font-weight: normal;
                font-size: rem(12px);
                line-height: rem(18px);
            }

            .line-item-total-price-container {
                padding-top: rem(79px);

                .strike-through {
                    display: none;
                }

                .line-item-total-price-amount {
                    font-weight: normal;
                    font-size: rem(14px);
                    line-height: rem(20px);
                    text-align: right;
                    letter-spacing: rem(-0.25px);
                }
            }

            .add-increment-btn {
                border: none;
                background-color: $ps-beige;
            }

            .remove-increment-btn {
                border: none;
                background-color: $ps-beige;
            }

            .line-item-quantity {
                padding-top: rem(32px);
                margin-left: rem(-4px);
            }

            .mini-cart-line-item-qty {
                font-style: normal;
                font-size: rem(12px);
                line-height: rem(20px);
                padding-top: rem(2px);
            }
        }

        .product-summary {
            .row:last-child {
                .card:last-child {
                    border-bottom: none;

                    .card-body {
                        padding-bottom: rem(18px);
                    }
                }
            }
        }

        .bfx-price-container {
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .minicart-footer {
            border-top: none;

            .checkout-btn {
                width: rem(340px);
                font-weight: bold;
                font-size: rem(12px);
                height: rem(44px);
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: $white;
            }

            .promo-cta-container {
                margin-right: rem(15px);
                padding-bottom: rem(10px);
                line-height: 1;
            }

            .promo-cta-link {
                font-weight: normal;
                font-size: rem(10px);
                letter-spacing: rem(-0.20625px);
                text-decoration-line: underline;
                color: $black;
            }

            .mini-cart-promo-form-container {
                padding: rem(10px) rem(15px) rem(10px) rem(15px);
            }

            .promo-code-container {
                padding-bottom: rem(10px);
            }

            .coupon-code-field {
                font-weight: normal;
                font-size: rem(14px);
                line-height: rem(22px);
                letter-spacing: rem(-0.25px);

                &::placeholder {
                    color: $medium-gray;
                }
            }

            .promo-code-btn {
                font-weight: bold;
                font-size: rem(12px);
            }

            .promo-form-container {
                height: rem(44px);

                .form-control {
                    height: rem(44px);
                }
            }

            .checkout-and-applepay {
                max-width: 100%;
                flex: 0 0 100%;

                .checkout-btn {
                    width: 100%;
                    align-items: center;
                    display: flex;
                    height: 44px;
                    justify-content: center;
                    line-height: 44px;
                    padding: 0 !important;
                    
                    svg {
                        margin-right: 6px;
                        margin-bottom: 2px;
                    }
                }
            }

            .minicart-pp-btn {
                .paypal-content-btn {
                    max-width: 100%;
                    flex: 0 0 100%;
                    width: 100%;
                }
            }

            .mini-cart-promo-text {
                margin-top: rem(5px);
                font-weight: normal;
                font-size: rem(10px);
                line-height: rem(14px);
            }

            .estimated-total {
                padding-top: rem(18px);
                border-top: 1px solid $light-gray;

                .sub-total-label .sub-total {
                    font-style: normal;
                    font-weight: 600;
                    font-size: rem(16px);
                    line-height: rem(22px);
                    letter-spacing: rem(-0.25px);
                    color: $black;
                }
            }

            .before-tax-text {
                font-style: italic;
                font-weight: normal;
                font-size: rem(10px);
                line-height: rem(16px);
                color: $medium-gray;
            }

            .mini-cart-product-carousel-container {
                padding-top: rem(18px);
                margin-right: rem(-15px);
            }
        }
    }

    .popover {
        background-color: transparent;
    }

    .popover.popover-bottom {
        @include media-breakpoint-up(md) {
            top: rem(32px);
        }
        @include media-breakpoint-down(sm) {
            top: rem(32px);
            right: rem(-15px);
            width: 100vw;
            max-width: 100vw;
            min-height: rem(241px) !important;
            height: rem(241px) !important;
        }

        &.show {
            border: rem(1px) solid transparent;
        }
    }

    .product-image {
        max-height: 150% !important;
    }

    .mobile-mini-cart.container.cart {
        border: none;
        height: rem(241px) !important;
        width: 100vw !important;
        left: 0;
        z-index: 9999999;
        -webkit-box-shadow: rem(1px) rem(14px) rem(15px) rem(4px) rgba(0, 0, 0, 0.34);
        box-shadow: rem(1px) rem(14px) rem(15px) rem(4px) rgba(0, 0, 0, 0.34);

        .mobile-minicart-header {
            background-color: $black;
            height: rem(38px);

            .minicart-title {
                font-style: normal;
                font-weight: bold;
                font-size: rem(12px);
                line-height: rem(18px);
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: $white;
            }
        }

        .products-added-card {
            height: rem(140px);
            width: 100% !important;
            padding-top: rem(12px);
            padding-bottom: rem(12px);
        }

        .mini-cart-mobile-view-cart-btn {
            border: rem(1px) solid black;
            font-style: normal;
            font-weight: bold;
            font-size: rem(12px);
            line-height: rem(18px);
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            height: rem(37.25px);
            width: rem(188px);
            padding-top: rem(9px);
        }

        .mini-cart-mobile-checkout-btn {
            color: $white;
            border: rem(1px) solid black;
            font-style: normal;
            font-weight: bold;
            font-size: rem(12px);
            line-height: rem(18px);
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            height: rem(37.25px);
            width: rem(188px);
            padding-top: rem(9px);
        }
    }

    .mini-cart-mobile-close-button {
        border: none;
    }

    img.mobile-mini-cart-close-img {
        background-color: $black !important;
    }

    .js-mini-cart-product-tile-carousel {
        .product-tile {
            .tile-body {
                .pdp-link {
                    padding-right: 0;

                    .link {
                        font-size: rem(12px);
                    }

                    .btn-likes {
                        display: none;
                    }
                }

                .price {
                    .value {
                        font-size: rem(12px);
                    }
                }
            }
        }
    }

    .text-product-tag {
        min-height: 0;
    }

    .pdp-link {
        padding-bottom: 0 !important;

        .link {
            color: $black;
        }
    }

    .price {
        line-height: 1rem !important;
    }

    .slick-arrow {
        height: rem(20px) !important;
        width: rem(20px) !important;
        background-size: rem(20px);
        margin-top: -90px;
    }

    .mini-cart-qty-span {
        font-family: $base-font-family;
        padding-top: rem(5px);
        padding-bottom: rem(5px);
        line-height: rem(18px);
        padding-left: rem(3px);
    }

    .custom-select.quantity {
        font-family: $base-font-family;
        width: rem(27px) !important;
    }

    .mini-cart-product-line-item-details {
        overflow-y: hidden;
    }

    .quantity-form {
        position: absolute;
        bottom: rem(-4px);
    }

    .mini-cart-product-carousel-cta {
        font-weight: 500;
        font-size: rem(14px) !important;
        line-height: rem(22px);
        letter-spacing: rem(-0.166667px);
        color: $black;
    }
}

.desktop-mini-cart {
    .product-line-item-details {
        .item-attributes {
            padding-left: rem(15px);
        }
    }
}

.header-height-B,
.header-height-App {
    .minicart {
        .popover.popover-bottom {
            top: rem(20px);
            right: (-19px);
            width: 100%;

            .mini-cart-padding-top {
                padding-top: rem(24px);
            }
        }
    }
}

.sticky-add-to-cart {
    @include media-breakpoint-down(sm) {
        position: fixed;
        bottom: 0;
        background: $secondary;
        transition: $transition-base;
        padding: 0 rem(15px) rem(15px);
        width: 100%;
        left: 0;
        z-index: 999;

        .button-total {
            display: block;
            padding-top: rem(12px);
            padding-bottom: rem(12px);
            transition: $transition-base;

            .sub-total-label {
                font-weight: $font-weight-semibold;
            }

            p {
                font-size: rem(16px);
                line-height: rem(22px);
            }
        }
    }
}

.coupon-onlyonce-message {
    display: none;
}

.coupon-error {
    span {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: $danger;
    }
}

.coupon-missing-error {
    display: none;
}

.mini-cart-a {
    .minicart-quantity {
        top: 0.3125rem;
    }

    .popover.popover.popover-bottom {
        top: rem(29px);
        right: rem(-19px);
    }
}

.mini-cart-product-tile-carousel-slide {
    padding-left: rem(6px);
    padding-right: rem(6px);
    width: rem(120px);
}

.apple-pay-cart.btn {
    display: none;
}

@media not all and (min-resolution: 0.001dpcm) {
    .apple-pay-cart.btn {
        display: block;
    }
}

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-cart.btn {
        display: block;
    }
}

.popover-show {
    .page {
        overflow: visible;
    }
}

.mini-cart-icon {
    background-image: $svg-mini-cart-black;
    width: 16px;
    height: 18px;

    [data-has-transparent-nav="true"][data-action="Home-Show"] &,
    [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
        background-image: $svg-mini-cart-white;

        &.is-black {
            background-image: $svg-mini-cart-black !important;
        }
    }
}