@import "../variables";

@mixin square($width, $height, $mobileHeight, $color) { // Source:  https://gist.github.com/kogakure/4769904
    @include media-breakpoint-down(sm) {
        height: $mobileHeight;
    }

    border: 0.063em solid $color;
    width: $width;
    height: $height;
    background: $color;
    border-radius: 0;
}

$swatch-colors: (
    'beige': $ps-beige,
    'black': $black,
    'blue': $blue,
    'brown': #a52a2a,
    'gold': #ffd700,
    'green': $ps-green,
    'grey': #8f979d,
    'ivory': #fffff0,
    'multi': $trans,
    'navy': #000080,
    'orange': #ffa500,
    'pink': #fe249a,
    'purple': #800080,
    'red': #f00,
    'silver': $light-gray,
    'white': $white,
    'yellow': #ff0
);

@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {
        @include square(#{rem(35px)}, #{rem(24px)}, #{rem(30px)}, $colorValue);

        display: inline-block;
        position: relative;
        transition: $transition-base;

        &.disabled {
            opacity: 0.2;
        }

        &.selected,
        &:hover {
            border-color: $primary;
        }
    }

    .swatch-filter-#{$color} {

        @include square(#{rem(35px)}, #{rem(24px)}, #{rem(30px)}, $colorValue);

        background-color: $colorValue;
        display: block;
        position: relative;

        &.disabled {
            opacity: 0.2;
        }
    }
}
