/* Base Header Styling */

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.icon-height {
    height: rem(62px) !important;
}

.header-wrap--shift-up {
    position: fixed;
    top: -100%;
    width: 100%;
    z-index: 1031;
}

.header {
    position: relative;

    .account-dropdown,
    .js-likes-page-link {
        position: relative;

        &::before {
            position: absolute;
            left: rem(-5px);
            width: rem(25px);
            height: rem(25px);
            display: block;
            content: "";
        }
    }

    .js-likes-page-link {
        &::before {
            top: rem(-5px);
        }
    }
}

.header-app {
    .navbar-toggler {
        padding-left: rem(20px);
    }

    .header-app-search-link {
        padding-right: rem(5px);
    }
}

.navbar-header {
    height: rem(60px); /* 70/16 */

    .user,
    .country-selector,
    .search,
    .minicart {
        display: inline-block;
        margin: 0;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        height: auto;
        font-size: 1.6em;
        width: auto;
    }

    .user {
        position: relative;

        .acct-icon {
            padding-top: rem(2.5px);
        }

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }
}

.brand {
    position: absolute;
    left: 50%;
    display: block;
    text-align: center;

    img {
        width: 100%;
        height: auto;
    }

    @include media-breakpoint-up(lg) {
        width: 14.125em; /* 226/16 */
        margin-left: -7.0625em; /* 113/16 */
        padding-top: 0.5em;
    }

    @include media-breakpoint-down(md) {
        width: 4em; /* 64/16 */
        margin-left: -2em;
        padding-top: 0.8em;
    }
}

.main-menu {
    background-color: $dark-gray;

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}

.header-banner {
    background-color: $darker-gray;
    text-align: center;
    color: $white;

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            opacity: 1;
            color: $white;
            width: 100%;
            height: 100%;
            background-color: #{var(--skin-primary-color-1)};
        }
    }

    .content {
        margin-right: 1.5em;
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
}

.header-promo-row {
    .carouselItem {
        opacity: 0;
    }

    .slick-initialized {
        .carouselItem {
            opacity: 1;
        }
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

/* Custom Header Styling */

header {
    :focus {
        outline: none !important;
    }

    .brand {
        height: 3.813rem;
        padding-top: 0 !important;

        img {
            @include media-breakpoint-down(md) {
                width: rem(134px) !important;
                max-width: rem(134px);
            }
        }

        .holidayLogo {
            @include media-breakpoint-down(md) {
                width: rem(131.2px) !important;
                max-width: rem(131.2px);
            }
        }
    }

    .site-search,
    .origSearch {
        font-family: $base-font-family;
        font-weight: $font-weight-normal;
        border: none;
        height: initial;
        width: rem(200px);
        position: relative;
        margin-right: 1.625rem;

        .search-field {
            border-radius: 20px;
            height: rem(28px);
            padding-left: rem(15px);
            border-color: transparent;
            background-color: $bright-gray;

            [data-has-transparent-nav="true"][data-action="Home-Show"] &,
            [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
                background-color: transparent;
                border-color: $white;

                &::placeholder {
                    color: $white;
                }

                &.is-black {
                    border-color: transparent;
                    background-color: $bright-gray;

                    &::placeholder {
                        color: $black;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                border-radius: 0;
                border: none;
                padding-left: 25px;
                font-size: 16px;
            }
        }

        .search-button {
            background: transparent;
        }

        .search-icon {
            position: absolute;
            right: 15px;
            top: 9px;
            width: 12px;
            height: 15px;

            [data-has-transparent-nav="true"][data-action="Home-Show"] &,
            [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
                background-color: transparent;
            }

            @include media-breakpoint-down(md) {
                left: 10px;
                right: auto;
                top: 10px;
                width: 20px;
                height: 17px;
            }
        }

        *::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: $black;
        }

        *:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: $black;
            opacity: 1;
        }

        *::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: $black;
            opacity: 1;
        }

        *:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $black;
        }

        *::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $black;
        }

        *::placeholder {
            /* Most modern browsers support this now. */
            color: $black;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .origSearch {
        margin-top: 1.625rem;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }

        .search-bar {
            @include media-breakpoint-down(md) {
                display: flex;
                line-height: 1em;
                margin: 0;
                position: relative;
                width: 100%;
                align-items: center;
                background-color: #efefef;
                border-color: transparent !important;
            }
        }

        .search-field {
            @include media-breakpoint-down(md) {
                height: rem(39px);
                order: 3;
                width: 78%;
            }
        }

        .cumilate-search-close::after {
            display: block;
            width: 12px;
            height: 12px;
            content: '';
            background: url('../../images/headerBSearchCloseButton.svg') no-repeat;
        }

        .closeButtonWrapper {
            align-items: center;
            display: block;
            order: 4;
            text-align: right;
            @include media-breakpoint-down(md) {
                display: none;
            }

            .cumilate-search-close {
                appearance: none;
                background: none;
                border: 0;
                margin: 0;
            }

            .cumilate-search-close::after {
                display: block;
                width: 12px;
                height: 12px;
                content: '';
                background: url('../../images/headerBSearchCloseButton.svg') no-repeat;
            }
        }

        .close-button {
            padding: rem(20px) rem(15px) rem(10px) rem(1px);
            height: inherit;
            @include media-breakpoint-down(md) {
                padding: 0;
                align-items: center;
                order: 4;
                margin: 0 auto;
            }
        }
    }

    .nav-item {
        margin-bottom: 0 !important;

        .nav-link {
            color: $black;
            line-height: initial;

            &:hover {
                color: $black;
            }

            &::after {
                display: none;
            }
        }

        .nav-link.text-secondary {
            &:focus {
                color: white !important;
            }

            &:hover {
                color: white !important;
            }
        }
    }

    .navbar.bg-inverse {
        .navbar-nav {
            &#mobile-nav {
                .nav-link,
                .dropdown-link {
                    padding: rem(15px) rem(20px) !important;
                    font-size: rem(16px);
                    font-weight: $font-weight-medium;
                }

                .dropdown-item {
                    padding: 0 !important;
                    margin: 0;
                }
            }

            .nav-link {
                padding: rem(15px) rem(32px);
                font-family: $base-font-family;
                font-weight: $font-weight-normal;
                font-size: rem(16px);
            }

            .modalDiv {
                padding-top: 0.85rem;

                .preLovedImg {
                    width: 80px;
                }

                .logoBlack {
                    display: none;
                }
            }

            @media (max-width: $dsk-head-med) {
                .nav-link {
                    font-size: rem(14px);
                }

                .preLovedImg {
                    width: 64px !important;
                }
            }

            @media (max-width: $dsk-head-sml) {
                .nav-link {
                    font-size: rem(12.8px);
                }

                .modalDiv {
                    padding-top: 0.89rem !important;

                    .preLovedImg {
                        width: 62px !important;
                    }
                }
            }
        }
    }

    .store-locator-icon {
        margin-left: 1rem;
    }

    .like-icon {
        position: relative;
        display: inline-block;
        margin: 1.125em 1.25em 0;
        top: 1px;

        .heart-img {
            padding-top: rem(2.5px);
        }
    }

    .user {
        @include media-breakpoint-down(sm) {
            margin-right: rem(16px) !important;
        }
    }

    .search-mobile {
        .site-search {
            .search-field {
                font-size: rem(14px) !important;
                padding-left: 0;
            }
            @include media-breakpoint-down(lg) {
                display: block;
                height: 100%;
                width: 100%;
                padding-right: 0.75rem;
                padding-left: 0.75rem;

                .search-icon {
                    top: rem(5px);
                    right: 0.625rem;
                }

                .search-bar {
                    border-bottom: none;
                }
            }
        }
    }

    .navbar-toggler {
        @include media-breakpoint-down(sm) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    /* Page Header B Styles */

    .nav-b {
        height: 100vh !important;
    }

    .header-height-B,
    .header-height-App {
        padding-left: 0;

        @include media-breakpoint-up(md) {
            .hybrid-nav-desktop-link {
                cursor: pointer;
                padding-right: 0;
            }

            .uni:hover {
                color: #000 !important;
            }

            .menu-group-padding {
                padding-left: rem(20px);
                padding-right: rem(13px);
            }

            .nav-dropdown-position {
                padding-top: rem(6px);
            }

            .dropdown-menu {
                padding-left: rem(30px);
                margin-top: 0;
                border: 0;
            }

            .sub-cat-item {
                padding-right: rem(10px) !important;
                padding-left: rem(10px) !important;

                &:hover {
                    background-color: transparent !important;
                }
            }

            .hybrid-nav-large-font {
                font-style: normal;
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                color: #111;
                letter-spacing: -0.75px;
            }

            .sale-link {
                color: $danger;
            }

            .hybrid-nav-small-font {
                font-style: normal;
                font-weight: normal;
                font-size: rem(14px);
                line-height: rem(21px);
                letter-spacing: rem(-0.291667px);
                color: #111;
            }

            .store-locator-nav-font {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: -0.45px;
                color: #111;
            }

            .store-locator-nav-padding {
                padding-bottom: 0.125rem;
            }
        }

        .nav-item {
            @include media-breakpoint-up(xl) {
                border-top: none !important;
            }
        }

        .nav-b-item-clicked {
            background-color: whitesmoke !important;
            color: $black;
        }

        .nav-b-dropright {
            background-color: $black;
            min-width: rem(296px);
            min-height: 85%;
            height: 90%;
            padding-bottom: rem(40px);
            overflow: scroll;
            -ms-overflow-style: none;
            top: rem(1px) !important;

            .dropright-text {
                font-style: normal;
                font-weight: 600;
                font-size: rem(12px);
                line-height: rem(14px);
                letter-spacing: rem(-0.35px);
                color: #fff;
                padding-top: rem(15px);
                padding-bottom: rem(15px);
            }

            .nav-b-secondary-menu-link:last-child {
                margin-bottom: rem(30px);
            }

            .promo-applier {
                color: $white;
            }
        }

        .nav-b-dropright::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        .nav-b-selection-line {
            height: rem(1px);
            width: 100%;
            margin-left: rem(20px);
            margin-top: rem(5px);
            border: rem(0.5px) solid black;
        }

        .promo-row {
            padding-left: 30px;
            padding-right: 30px;
            background-color: $black;
        }

        .nav-b-secondary-menu-link {
            color: white;
            font-style: normal;
            font-weight: normal;
            font-size: rem(16px);
            line-height: rem(40px);
            letter-spacing: rem(-0.377143px);
            margin-left: rem(30px);
            display: block;
        }

        .top-secondary-link-padding {
            margin-top: rem(0);
        }

        .nav-b-sub-item::after {
            display: none;
        }

        .bg-whitesmoke {
            background-color: whitesmoke !important;
            height: 3.875rem !important;
            position: static;
            z-index: 2;

            + .menu-overlay {
                background-color: $black;
                left: 0;
                opacity: 0.5;
                position: fixed;
                top: -40px;
                width: 100%;
                z-index: 1;
                height: 40px;
            }
        }

        .secondary-call-to-action {
            margin-bottom: rem(0);
            padding: rem(24px);
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.33px;
        }

        .border-whitesmoke {
            border: 1px solid whitesmoke;
        }

        &.header-b-nav-icons {
            padding-right: rem(30px);
        }
    }

    .menu-toggleable-left.in {
        margin-right: 0 !important;
        width: 100vw;
    }

    .header-b-search-link {
        margin-right: rem(16px);
    }

    .search-modal {
        z-index: 1040;
    }

    .mobile-header-container-styling {
        padding-left: 0;
    }

    .nab-b-desktop-secondary-menu {
        background-color: $black;

        a {
            color: white;
        }
    }

    .nav-b-dropdown {
        background-color: $black;
        min-width: rem(296px);
        min-height: rem(838px);
        top: rem(-8px) !important;
        right: rem(200px) !important;
        margin-left: 0 !important;
    }

    .secondary-dropright {
        width: rem(400px);
    }

    /* Nav Styling */
    .nav-dropdown-styling {
        background-color: #f6f5f3;
        @include media-breakpoint-down(sm) {
            height: 100%;
        }
    }

    .nav-dropdown-position {
        position: static;
    }

    .footer-utility-row {
        @include media-breakpoint-down(lg) {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            min-height: rem(120px);
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }

        background-color: #eee;
    }

    .utility-div {
        @include media-breakpoint-down(sm) {
            flex: 0 0 auto;
            min-height: rem(120px);
            padding-left: 0;
            padding-right: 0;
        }
        @include media-breakpoint-up(md) {
            min-height: rem(158px);
            padding-bottom: rem(25px);
        }
    }

    .utility-heading {
        display: inline-block;
        color: $black;
        margin-bottom: rem(6px);
        font-style: normal;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(27px);
        padding-left: rem(32px);
    }

    .utility-row-icon {
        padding-top: rem(5px);
        padding-bottom: rem(5px);
    }

    .utility-subtext {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: rem(12px);
        line-height: rem(20px);
        text-align: center;
        color: #696969;
        padding-top: rem(5px);
        text-transform: initial;
        @include media-breakpoint-down(sm) {
            padding-top: 0;
            font-size: rem(10px);
            line-height: rem(14px);
            padding-bottom: rem(32px);
        }
    }

    .learn-more-link {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-size: rem(12px);
        line-height: rem(20px);
        text-align: left;
        color: #696969;
        font-weight: 700;
        padding-bottom: rem(26px);

        .learn-more-call-to-action {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .nav-item {
        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid black !important;
            background-color: #eee;
        }
    }

    /* Styles Html Generated by client menu.js */

    .navbar {
        @include media-breakpoint-down(sm) {
            background-color: whitesmoke !important;

            .close-menu {
                border: 0;
                background-color: #eee !important;
            }

            .utility-div {
                background-color: #eee;

                .utility-heading {
                    text-transform: initial;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            overflow: hidden;
        }
    }

    .top-category {
        @include media-breakpoint-down(lg) {
            border-top: none !important;
            margin-bottom: 0 !important;
            padding-left: 0 !important;

            .nav-link {
                color: $black !important;

                &:hover {
                    background-color: #eee !important;
                }
            }

            &:hover {
                background-color: #eee !important;
            }

            img {
                display: none;
            }
        }
    }

    .dropdown-item {
        .dropdown-link {
            color: $black;
        }

        @include media-breakpoint-down(lg) {
            background-color: #eee;
            margin-bottom: 0;
            padding-top: rem(13px) !important;
            padding-bottom: rem(13px) !important;
            font-style: normal;
            font-weight: 500;
            font-size: rem(14px);
            line-height: rem(21px);
            border-bottom: 1px solid black !important;
            padding-left: 0 !important;

            &:active {
                background-color: #eee;
            }

            &:hover {
                background-color: #eee;
            }

            .dropdown-link {
                padding: 0 rem(30px) !important;

                &:active {
                    background-color: #eee;
                }
            }

            .nav-link {
                background-color: #eee !important;

                &:hover {
                    background-color: #eee !important;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-top: rem(12px) !important;
            padding-bottom: rem(11px) !important;

            a {
                line-height: initial;
            }
        }
    }

    .top-category.dropdown-item {
        @include media-breakpoint-down(lg) {
            border-top: none !important;
            color: $black !important;
        }
    }

    .nav-menu {
        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
            background-color: #eee !important;
        }
    }

    .dropdown-link {
        @include media-breakpoint-down(md) {
            font-weight: 400;
            padding: 0 !important;
            line-height: 1.5rem;
            font-size: 1rem;

            &:hover {
                font-weight: 500 !important;
            }
        }
    }

    .dropdown-item.top-category {
        @include media-breakpoint-down(md) {
            a {
                padding-top: 1rem !important;
                padding-bottom: 1rem !important;
            }
        }
    }

    .new-tag {
        font-style: normal;
        font-weight: 600;
        font-size: rem(10px);
        line-height: rem(15px);
        letter-spacing: rem(-0.3125px);
        color: #789ddb;
        padding-bottom: rem(4px);
        position: absolute;
        padding-left: rem(4px);
    }

    .dropdown-menu {
        @include media-breakpoint-down(lg) {
            background-color: #eee;
        }

        @include media-breakpoint-up(xl) {
            margin-top: rem(-5px);
            top: auto !important;
            max-height: 100vh;
        }
    }

    .sale-dropdown {
        @include media-breakpoint-down(lg) {
            background-color: $danger !important;
            color: white;

            &:hover {
                color: $black;
                background-color: $danger !important;
            }
        }
    }

    .promotions-dropdown {
        border-bottom: 2px solid #646464;
        background-color: $black !important;
    }

    .nav-link:focus {
        background-color: #eee;
        color: $black;
    }

    .main-menu {
        background-color: white;
        @include media-breakpoint-down(lg) {
            height: 100%;
            overflow: auto;
            background-color: #eee !important;
        }

        &.main-menu-app {
            @include media-breakpoint-down(lg) {
                background-color: white !important;
            }
        }
    }

    .font-weight-500 {
        font-weight: 500 !important;
    }

    .navbar.bg-inverse {
        @include media-breakpoint-up(md) {
            background-color: white;
        }
    }

    .mobile-nav-back-button {
        padding: rem(13px) rem(13px) rem(12px) rem(20px) !important;
    }

    .sale-dropdown-text {
        color: $danger !important;
    }

    .content-asset {
        width: 100% !important;
    }

    .mobile-nav-height {
        height: 100vh;
        overflow: hidden;
    }

    .bg-eee {
        background-color: #eee !important;
        padding: rem(25px) rem(32px) 0 0;
        text-align: right;
    }

    .page-header-b-content-asset {
        .nav-b-item-clicked {
            background-color: whitesmoke !important;
        }

        &.clicked-top-padding {
            padding-top: rem(4px);
        }
    }

    .account-dropdown:hover {
        .logged-in-menu {
            display: block;
        }
    }

    .account-icon-login-toggle::after {
        display: none;
    }

    .open {
        display: block !important;
    }

    .dropdown-menu.account-icon-dropdown-menu {
        min-width: rem(414px);
        min-height: rem(474px);
        border: none;
        padding-bottom: 0;
    }

    .dropdown-menu.account-icon-dropdown-menu.logged-in-menu {
        min-width: rem(280px);
        min-height: rem(280px);
        right: rem(-90px) !important;
        left: auto;
    }

    .logged-in-menu {
        width: rem(280px);
        height: rem(280px);
        padding-top: 0;
        background-color: transparent;

        .account-dropdown-content {
            background-color: $black;
            color: $white;
            padding: rem(25px) rem(30px) rem(24px) rem(30px);
            line-height: 1.5;
            box-shadow: 1px 14px 15px 4px rgba(0, 0, 0, 0.34);
        }

        .logged-in-dropdown-hover-pad {
            background-color: transparent;
            @include media-breakpoint-up(md) {
                height: rem(19px);
            }
        }

        a {
            color: $white;
        }

        li {
            list-style-type: none;
        }

        .login-welcome {
            font-weight: 600;
            font-size: rem(14px);
            letter-spacing: rem(-0.2px);
            padding-bottom: rem(20px);
        }

        .rewards-border {
            border-bottom: 1px solid #949494;
            margin-bottom: rem(6px);
            margin-right: rem(3px);
            margin-left: rem(3px);
        }

        .rewards-font-styling {
            font-weight: 400;
            font-size: rem(14px);
            letter-spacing: rem(-0.2px);
            color: #949494;
        }

        .rewards-number {
            font-weight: 600;
            font-size: rem(14px);
            letter-spacing: rem(-0.2px);
            color: $white;
        }

        .points-postioning {
            margin-bottom: rem(20px);
        }

        .logged-in-dropdown-link {
            color: $white;
            letter-spacing: rem(-0.2px);
            margin-bottom: 0;
            font-style: normal;
            font-weight: normal;
            font-size: rem(14px);

            .store-pickup-name {
                text-decoration: underline;
            }
        }

        .logout-link {
            margin-top: rem(20px);
        }
    }

    #account-signed-in-dropdown {
        &:hover {
            .logged-in-menu {
                display: block !important;
            }
        }

        &::after {
            display: none;
        }
    }

    #mobile-logged-in-accont-icon::after {
        display: none;
    }

    .logged-in-account-icon-toggle::after {
        display: none;
    }

    .opacity-0 {
        opacity: 0;
    }

    .header-b-mobile-search-button {
        img {
            padding-top: rem(2.5px);
        }
    }

    .acct-icon {
        padding-top: rem(2.5px);
    }

    // Header No Menu
    &.header-no-menu {
        background: $white;
        border-bottom: 1px solid $light-gray;

        .logo-home {
            margin: rem(2px) 0;
        }

        .back-btn {
            margin-left: rem(15px);
            padding: rem(17px) 0;
            z-index: 2;

            @include media-breakpoint-up(xl) {
                margin-left: rem(25px);
            }
        }
    }

    .text-black {
        color: $black !important;
    }

    .hybrid-nav-desktop-link:focus {
        background-color: #0000 !important;
    }

    [data-action="Home-Show"] &.inverted-color,
    [data-action="Sites-pacsun-Site"] &.inverted-color {
        .stroke-white * {
            stroke: white;

            .stroke-4 {
                stroke-width: 0.4;
            }
        }
    }

    .nav-b-desktop-toggler {
        line-height: 2.25em;
        height: auto;
        font-size: 1.6em;
        width: auto;
        background-color: transparent;
        border-radius: 0;
        border: 1px solid transparent;
    }
}

@media screen and (-ms-high-contrast: $active), (-ms-high-contrast: $none) {
    header .site-search .search-field {
        padding: 0 1.3rem 0 0.93rem;
    }
}

body.full-page-mask {
    .modal-backdrop {
        z-index: 1040;
        top: 0;
    }
}

.coupons-and-promos {
    .remove-coupon {
        margin-top: rem(-2px);
    }

    .coupon-price-adjustment {
        margin-bottom: rem(5px);
        padding-bottom: rem(5px);

        &:not(:last-of-type) {
            border-bottom: 1px solid $border-color;
        }
    }

    .coupon-code,
    .promotion-information {
        font-size: rem(10px);
        line-height: rem(14px);

        button {
            svg {
                width: rem(8px);
                height: rem(8px);
            }
        }
    }

    .promotion-information {
        padding-bottom: rem(6px);
    }
}

.logo-home {
    outline: none;

    /* stylelint-disable */
    &:focus,
    &:focus-visible {
        outline: none;
    }
    /* stylelint-enable */

    img {
        outline: none;

        /* stylelint-disable */
        &:focus,
        &:focus-visible {
            outline: none;
        }
    }
    .holidayLogo {
        width: 97%;
    }
}

.header-border {
    @include media-breakpoint-up(xl) {
        border-bottom: rem(1px) solid $light-gray;
    }
}
