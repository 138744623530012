// Text from Figma Style Guide
.text {
    // Body
    &-body-content {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.25px;
    }
    // Body Links
    &-body-link {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.25px;
        text-decoration: underline;

        &:hover {
            opacity: 0.7;
        }
    }
    // Product Card name
    &-product-tile-name {
        font-size: 14px;
        line-height: 16px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.25px;
        }
    }
    // PDP Product Name
    &-product-name {
        letter-spacing: -0.25px;
        font-size: 20px;
        line-height: 24px;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 22px;
        }
    }
    // Product Card Price
    &-product-tile-price {
        line-height: 16px;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            line-height: 18px;
        }
    }
    // Product Card Original Price
    &-product-tile-price-original {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        text-decoration: line-through;

        @include media-breakpoint-up(md) {
            line-height: 18px;
        }
    }
    // Footer Links
    &-footer-link {
        font-size: 14px;
        line-height: 32px;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }

        &:hover {
            opacity: 0.7;
        }
    }
    // Footer Headers
    &-footer-header {
        font-size: 14px;
        line-height: 32px;
        font-weight: 700;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
    // Form Labels
    &-form-labels {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }
    // Pagination
    &-pagination {
        font-size: 14px;
        line-height: 18px;
        color: #696969 !important;
    }
    // Pagination Active State
    &-pagination-active {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
    // PDP Sizes
    &-pdp-size {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    // PDP Sizes Selected
    &-pdp-size-active {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    // Buttons/CTA
    &-btn-cta {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: 3%;
    }
    // Captions, Legal, and Breadcrumbs
    &-caption {
        font-size: 12px;
        line-height: 16px;
    }
    // Product Promotions and Color Options
    &-promotion {
        font-size: 10px;
        line-height: 14px;
    }
    // PRODUCT CARD Tags
    &-product-tag {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
    }
    // Product Tile Brand
    &-product-brand {
        font-size: 12px;
        line-height: 12px;
        padding-bottom: 4px;
    }
}

.fw-semi-bold {
    font-weight: 500;
}

.ls-semi-condensed {
    letter-spacing: -0.5px;
}
