.yotpo-reviews-header {
    float: left;
    width: 100%;
    margin: 0;
    padding: 32px 0 19px;
    font-size: 15px;
    box-sizing: border-box;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}

.yotpo-label-container {
    display: none;
}

.yotpo.yotpo-main-widget {
    font-family: $base-font-family !important;

    .share-options.share-review::before {
        content: "\e608";
    }

    .yotpo-thank-you {
        .yotpo-thankyou-header .yotpo-icon.yotpo-icon-heart {
            display: none;
        }

        .yotpo-thankyou-footer .yotpo-default-button.yotpo-icon-btn {
            background-color: $black;
        }
    }

    .yotpo-reviews-filters .yotpo-icon-btn {
        padding: 6px 10px;
    }

    &#yotpo-reviews-top-div {
        margin-bottom: 32px;
    }
}

.yotpo-review .yotpo-icon.yotpo-icon-seperator::before {
    content: none;
}

.yotpo .main-widget {
    div,
    span,
    p,
    a,
    img,
    i,
    strong,
    sup,
    ul,
    li,
    form,
    label,
    .yotpo-review .yotpo-footer .y-label,
    .yotpo-review .yotpo-main .content-title,
    .yotpo-review .yotpo-main .content-review,
    .yotpo-comment .yotpo-footer .y-label,
    .yotpo-comment .yotpo-main .content-title,
    .yotpo-comment .yotpo-main .content-review,
    .yotpo-question .yotpo-footer .y-label,
    .yotpo-question .yotpo-main .content-title,
    .yotpo-question .yotpo-main .content-review,
    .yotpo-onsite-upload .yotpo-footer .y-label,
    .yotpo-onsite-upload .yotpo-main .content-review,
    .yotpo-onsite-upload .yotpo-main .content-title,
    .yotpo .yotpo-nav li,
    .y-label,
    span.y-label {
        font-family: $base-font-family !important;
        color: $black;
    }

    .transparent-color-btn span.yotpo-icon {
        color: $black;
    }

    div.yotpo-filter-tag .filter-tag-text {
        color: $white;
        position: relative;
        top: 1px;
    }

    .yotpo-default-button {
        &.write-question-button.write-first-question-button,
        &.write-review-button.write-first-review-button {
            background-color: $black;
        }
    }

    .write-question-review-buttons-container .write-question-review-button .write-question-review-button-text {
        color: $black;
    }

    .bottom-line-items-container .reviews-qa-labels-container .reviews-qa-label {
        color: $black;
    }

    .yotpo-empty-search-results .empty-search-results-clear-all-btn {
        top: 75px;
    }

    .yotpo-empty-search-results {
        height: 180px;
    }

    .yotpo-reviews-header.yotpo-active {
        margin: 0;
        padding-left: 115px;
    }

    .yotpo-reviews-header {
        .yotpo-drop-down-layout {
            position: relative;
            z-index: 1;
        }
    }

    .yotpo-review .yotpo-header .yotpo-icon-profile,
    .yotpo-comment .yotpo-header .yotpo-icon-profile,
    .yotpo-question .yotpo-header .yotpo-icon-profile,
    .yotpo-onsite-upload .yotpo-header .yotpo-icon-profile {
        display: none;
    }

    .yotpo-review .yotpo-main,
    .yotpo-review .yotpo-footer,
    .yotpo-question .yotpo-main,
    .yotpo-question .yotpo-footer {
        margin-left: 0;
    }

    .yotpo-nav ul .yotpo-nav-tab span,
    .yotpo-nav-dropdown span:not(.yotpo-icon) {
        color: $black;
    }

    .write-question-button {
        margin-left: 0;
    }

    // PSCC-38 Hide first review and first question buttons until bug is resolved
    .yotpo-first-review,
    .write-first-question-button {
        display: none;
    }
}

.rwd-pdp-main #yotpo-bottomline-top-div.yotpo {
    .text-m {
        padding-left: 7px;
        text-decoration: underline !important;
    }

    .yotpo-display-wrapper {
        padding-top: 10px;
    }
}

#yotpo-reviews-top-div.yotpo .write-review {
    .connect-wrapper {
        padding-left: 0;
        padding-right: 273px;
    }

    .socialize-wrapper {
        right: 0;

        .socialize {
            float: right;

            .yotpo-default-button {
                background-color: $black;
            }
        }
    }

    .submit-button {
        float: left;
    }
}

.yotpo-display-wrapper .standalone-bottomline {
    padding-top: 5px;

    .text-m {
        padding-left: 5px;
        font-size: 12px;
    }
}

#yotpo-reviews-top-div {
    :focus {
        outline: 2px solid $dark-gray;
    }
}

.yotpo-small {
    &.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-star,
    &.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-half-star,
    &.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-empty-star,
    &.yotpo.bottomline .yotpo-bottomline .yotpo-icon-star,
    &.yotpo.bottomline .yotpo-bottomline .yotpo-icon-half-star,
    &.yotpo.bottomline .yotpo-bottomline .yotpo-icon-empty-star,
    &.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-star,
    &.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-half-star,
    &.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-empty-star {
        font-size: 15px;
    }
}

#yotpo-referred-customer-popup {
    padding: 25px;
}

#yotpo-referred-coupon-code {
    margin-top: 20px;
}

#yotpo-referred-coupon-code-error {
    display: none;
    color: $red;
}

.yotpo-widget-referral-widget {
    .yotpo-right-align-background .yotpo-tile-box.yotpo-is-mobile {
        width: 80%;
    }

    .yotpo-is-mobile.yotpo-tile-box.yotpo-final-view {
        padding-top: 120px;
    }
}

.yotpo-display-wrapper {
    font-family: $base-font-family;
}
