$breakpoint-name: "lg";
$breakpoint-name: "lg" !default;
$breakpoint-XXL: 1400px;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1) !default;

.navigation {
    background: $white;
    border-bottom: rem(1px) solid $primary;

    @include media-breakpoint-up(xl) {
        &:hover {
            background: $white !important;
        }
    }

    @include media-breakpoint-up(xl) {
        &.is-mega-menu {
            background: $white !important;
        }
    }

    [data-has-transparent-nav="true"][data-action="Home-Show"] &,
    [data-has-transparent-nav="true"][data-action="Sites-pacsun-Site"] & {
        background: transparent;
        margin-bottom: -3.875rem;
        border: none;

        @include media-breakpoint-up(xl) {
            &.is-mega-menu {
                margin-bottom: 0;
            }
        }

        &.search-open {
            margin-bottom: 0;
        }
    }
}

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed !important;
            left: -100% !important;
            top: 0 !important;
            bottom: 0 !important;
            transition: $slide-out-animation !important;
            display: block !important;
            max-width: 100% !important;

            &.in {
                min-width: 100% !important;
                left: 0 !important;
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 2px 0;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: -9px;
        }

        @media (min-width: $breakpoint-XXL) {
            .navbar-nav .nav-item + .nav-item {
                margin-left: -3px;
            }
        }

        .navbar-nav .nav-link {
            padding: 0.8rem;
            white-space: nowrap;
        }
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    @include media-breakpoint-up(xl) {
        background-color: $white;
        color: $black;
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    @include media-breakpoint-down(lg) {
        background-color: #eee;
        color: $black;

        .sale-dropdown {
            color: white;
        }
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $white;
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 15px;
        background-color: $close-menu-bg;
        border-bottom: 1px solid $grey3;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {
        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em; /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $grey2;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                padding-left: 1rem;
            }

            .show > .dropdown-menu {
                left: 0;
                padding-bottom: 5rem;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
                overflow-y: auto;
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 90%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {
        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        padding: 0 0 0 1em;

        .dropdown-link {
            display: block;
            padding: 0.425em 5em 0.425em 0;
        }

        + .dropdown-item {
            border-top: 1px solid $close-menu-bg;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
            }
        }
    }
}

.top-category {
    border-top: none !important;
}

@include media-breakpoint-only(md) {
    .menu-toggleable-left.navbar-toggleable-lg {
        max-width: 70% !important;
    }
}

@include media-breakpoint-only(lg) {
    .menu-toggleable-left.navbar-toggleable-lg {
        max-width: 70% !important;
    }
}

@include media-breakpoint-down(lg) {
    .promotions-trends-dropdown {
        background-color: $black !important;

        &:hover {
            background-color: $black !important;
        }

        &:focus {
            background-color: $black !important;
        }
    }

    .secondary-highlight {
        background-color: $black !important;
        color: white !important;

        &:hover {
            background-color: $black !important;
            color: white !important;
        }

        &:focus {
            background-color: $black !important;
            color: white !important;
        }
    }
}

body.mobile-nav-height {
    overflow: hidden;
    position: fixed;
    width: 100%;

    .page {
        position: relative;
        z-index: 30;
    }
}

.mobile-menu-logo-container {
    margin-top: rem(25px);

    img {
        height: auto;
    }

    .mobile-menu-logo {
        width: 12.8125rem;
    }

    .holidayLogo {
        width: 10.8125rem;
    }
}

.mobile-menu-close {
    top: rem(10px);
    right: rem(15px);
}

.UsableNetAssistive {
    right: 1.875rem;
    top: 0;
    line-height: 3rem;
    font-size: 0.75rem;
    z-index: 1;
    text-decoration: underline;
    position: absolute;
    display: flex;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: auto !important;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

#usntA40Toggle {
    z-index: 9948029 !important;

    &.usntA40bottom-right {
        right: rem(10px) !important;
        bottom: rem(10px);
    }

    &.usntA40top-right {
        right: rem(10px) !important;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}
