.tile-body,
.product-price-rating {
    .price {
        .sales {
            font-weight: 400;
        }

        .strike-through {
            text-decoration: line-through;
            color: $light-gray;
            margin-right: 0;
            margin-left: rem(6px);
        }
    }
}

.cart-page {
    .product-info {
        .price {
            .strike-through {
                margin-right: 0;
            }
        }
    }

    .bfx-remove-element {
        .tax-total {
            font-size: rem(16px);
        }
    }

    .saved-product-info {
        .price {
            .strike-through {
                margin-right: 0;
            }
        }
    }

    .bfx-remove-element {
        .tax-total {
            font-size: rem(16px);
        }
    }
}

.markdown-pricing {
    color: $danger;

    .price {
        color: $danger;
    }
}

.bfx-sale-price {
    color: $danger;
}
