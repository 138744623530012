// Custom badge icons
// Usage :
// <i class="ps-icon ps-icon-trending"></i>

.ps-icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    background-position: center;
    min-width: rem(13px);
    min-height: rem(16px);
    background-repeat: no-repeat;

    // trending lightning icon
    &-trending {
        width: rem(8px);
        height: rem(13px);
        background-image: $svg-icon-trending;
    }

    // new icon
    &-new {
        width: rem(13px);
        height: rem(13px);
        background-image: $svg-icon-new;
    }

    // exclusive icon
    &-exclusive {
        width: rem(13px);
        height: rem(16px);
        background-image: $svg-icon-exclusive;
    }

    // sustainable icon
    &-sustainable {
        width: rem(12px);
        height: rem(9px);
        background-image: $svg-icon-sustainable;
    }

    // low stock icon
    &-lowstock {
        width: rem(11px);
        height: rem(14px);
        background-image: $svg-icon-low-stock;
    }
}

.product-tags {
    margin: rem(7px) 0 0 rem(8px);
    position: absolute;
    z-index: 1;

    @include media-breakpoint-up(md) {
        margin: rem(7px) 0 0 rem(11px);
    }

    .text-product-tag {
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    &.seo {
        bottom: 0;
        margin: 0 0 rem(5px) rem(5px);
        z-index: auto;

        @include media-breakpoint-up(md) {
            margin: 0 0 rem(10px) rem(10px);
        }

        .text-product-tag {
            background: rgba(17, 17, 17, 0.6);
            border-radius: rem(22px);
            color: $white !important;
            font-size: rem(10px);
            font-weight: $font-weight-medium;
            justify-content: center;
            line-height: rem(10px);
            padding: rem(6px) rem(10px);
        }
    }
}
