.stack-container {
    &.stack-container--init {
        .stack__item {
            opacity: 0;
        }
    }
}

.stack {
    margin: 0 auto;
    position: relative;
    width: rem(320px);
    height: rem(320px);
    padding: 0;
    list-style: none;
    pointer-events: none;
}

.stack__item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: rem(6px);
    text-align: center;
    overflow: hidden;
    user-select: none;
    pointer-events: auto;
}

.stack__item img {
    width: 100%;
    display: block;
    pointer-events: none;
}

.stack__item--reject,
.stack__item--accept {
    pointer-events: none;
}

/************** Default ****************/

.stack-wrapper--default {
    width: 100%;
    padding-right: rem(15px);
    padding-left: rem(15px);
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}

.stack-container--default {
    &.stack-container--init {
        &[data-visible-items-count="2"] {
            padding: 0 2.5% 2.5% 0;
        }

        &[data-visible-items-count="3"] {
            padding: 0 5% 5% 0;
        }
    }

    &:not(.stack-container--init) {
        .stack__item:first-child {
            z-index: 4;
        }
    }
}

.stack--default {
    width: 100%;
    height: auto;
    padding-bottom: 107%;

    .stack__item--accept,
    .stack__item--reject {
        z-index: 4 !important;
    }

    .stack__item__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .stack__item__figure {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        margin: 0;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 13.94%, rgba(0, 0, 0, 0.6) 73.71%);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.stack--default .stack__item--reject {
    animation: stackDefaultReject 0.5s forwards;
}

@keyframes stackDefaultReject {
    to {
        opacity: 0;
        transform: translate3d(-60%, 0, 0);
    }
}

.stack--default .stack__item--accept {
    animation: stackDefaultAccept 0.5s forwards;
}

@keyframes stackDefaultAccept {
    to {
        opacity: 0;
        transform: translate3d(60%, 0, 0);
    }
}
