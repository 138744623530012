.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-y-auto {
    overflow-y: auto;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-all {
    pointer-events: all;
}

.product-name {
    font-size: $product-name-fs;
    line-height: $product-name-lh;
}

// banner Titles absolute positioning classes

.top-left {
    top: 0;
    left: 0;
}

.bottom-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
}

.top-right {
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
}

.bottom-right {
    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
}

.left-center {
    left: 0;
    right: auto;

    @include centerElement(vertical, absolute);
}

.right-center {
    right: 0;
    left: auto;

    @include centerElement(vertical, absolute);
}

.bottom-left-with-button {
    @include pos(62px 0, bottom left, $auto-overwrite: true);
}

.mobile-top-left {
    @include media-breakpoint-down(sm) {
        @include pos(0 0, top left, $auto-overwrite: true);
    }
}

.mobile-top-right {
    @include media-breakpoint-down(sm) {
        @include pos(0 0, top right, $auto-overwrite: true);
    }
}

.mobile-bottom-right {
    @include media-breakpoint-down(sm) {
        @include pos(0 0, bottom right, $auto-overwrite: true);
    }
}

.mobile-bottom-left {
    @include media-breakpoint-down(sm) {
        @include pos(0 0, bottom left, $auto-overwrite: true);
    }
}

.mobile-center {
    @include media-breakpoint-down(sm) {
        @include pos(0 0, bottom left, $auto-overwrite: true);
    }
}

// sticky-fill base styles
.is-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0 !important;

    &.bottom-sticky {
        bottom: 0 !important;
        top: auto !important;
    }
}

.is-sticky-soft {
    @include media-breakpoint-up(md) {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}

.is-sticky {
    &::before,
    &::after {
        content: "";
        display: table;
    }
}

.small-paddings {
    margin-left: rem(-8px);
    margin-right: rem(-8px);

    div[class^="col-"] {
        padding-left: rem(8px);
        padding-right: rem(8px);
    }
}
