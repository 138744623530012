@import "variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

/* Bootstrap Modal */
.genModal {
    .modal-dialog {
        max-width: 500px;
    }

    .modal-header,
    .modal-footer {
        border-bottom: none;
        border-top: none;
    }

    .modal-content {
        border-radius: 8px;
    }

    .close {
        text-align: right;
        padding: 0.5rem 0.5rem 0 0;
        font-weight: normal;
        opacity: 0.7;
    }

    .modal-body {
        padding: 1rem;
        text-align: center;
    }

    .modal-footer {
        padding-bottom: 2rem;
    }

    .btn {
        width: 100%;
        border-radius: 4px;
    }
}
